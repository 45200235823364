import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import {LoginButton} from './components/Login';
import {LogoutButton} from './components/Logout';
import { Chip } from 'primereact/chip';

export const AppTopbar = (props) => {

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      const [usuario, setUsuario] = useState(null);
      const [imagen, setImagen] = useState(null);

      useEffect(() => {
        if(!isLoading && !isAuthenticated) 
         {}
        else
        {
            if(!isLoading) 
            {
                setUsuario(user.email)
                setImagen(user.picture)
        
            }
               
        }

    }, [isLoading, isAuthenticated]);

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/LOGO_KREATIVLAB.png' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
                {/* <span>Laboratorio digital</span>  */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <div className="p-link layout-topbar-button">
                            <label htmlFor="checkOption1">{usuario}</label>
                        </div>
                    </li>
                    

                    {isAuthenticated && !isLoading ?
                            (<>
                            <LogoutButton/>
                    <script>
                    function myFunction() {
                        //alert("isLoading: " +isLoading+", isAuthenticated: " +isAuthenticated)
                    }
                    </script>
                            </>)
                            :(
                                <>
                                <LogoutButton/>
                            <LoginButton/>
                    <script>
                    function myFunction() {
                        //alert("isLoading: " +isLoading+", isAuthenticated: " +isAuthenticated)
                    }
                    </script>
                            </>)}
                            

                    {/* <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-sign-out"/>
                            <span>logout</span>
                        </button>
                    </li> */}
                    <li>
                        <button className="p-link layout-topbar-button">
                        <Chip image={imagen} className="mr-2 mb-2 custom-chip" />
                        </button>
                    </li>
                </ul>
        </div>
    );
}
