import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProcesosService } from '../service/laboratorio/ProcesosService';
//import { MyLoader } from '../components/placeholders/MyLoader';
import { Password } from 'primereact/password';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import ContentLoader, {List} from "react-content-loader"
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';

const Procesos = () => {

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }
    const history = useHistory();
    const [checkboxValue, setCheckboxValue] = useState([]);

 


    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setProceso({
                ...proceso,
                ["habilitado"]:e.checked
            });

           
    };

    let emptyProceso = {
        id: '',
        proceso: '',
        costo: 0,
        fechaCreacion: '',
        habilitado: true

    };


    const [procesos, setProcesos] = useState(null);
    const [procesoDialog, setProcesoDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [proceso, setProceso] = useState(emptyProceso);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const procesosService = new ProcesosService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                rolesModulosPorUsuarioService.mz(user.email,6).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        procesosService.getAll().then(data => {setProcesos(data);});
       
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);


    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option.rol}</span>
                </div>
            );
        }

        return 'Selecciona Roles';
    };

  const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.rol}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setProceso(emptyProceso);
        setSubmitted(false);
        setProcesoDialog(true);
    }

    const hideDialog = () => {

        setSubmitted(false);
        setProcesoDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveUsuario = () => {
        setSubmitted(true);

        if (proceso.proceso.trim()){
            let _procesos = [...procesos];
            let _proceso = { ...proceso };

            if (proceso.id) {
                const index = findIndexById(proceso.id);
                procesosService.update(proceso);
                _procesos[index] = _proceso;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'proceso actualizado', life: 3000 });
            }
            else {
                procesosService.create(proceso).then(data => _proceso.id = data.id);
                //usuariosService.getAll().then(data => setProcesos(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                _procesos.push(_proceso);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'proceso creado', life: 3000 });
            }

            setProcesos(_procesos);
            setProcesoDialog(false);
            setProceso(emptyProceso);
        }
    }

    const editProceso = (proceso) => {
        setProceso({ ...proceso });
        
        setProcesoDialog(true);
    }

    const confirmDeleteProduct = (proceso) => {
        setProceso(proceso);
        setDeleteClientDialog(true);
    }

    const deleteProduct = () => {
        let _procesos = procesos.filter(val => val.id !== proceso.id);
        setProcesos(_procesos);
        setDeleteClientDialog(false);
        setProceso(emptyProceso);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuario Deleted', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < procesos.length; i++) {
            if (procesos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteClientsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _procesos = procesos.filter(val => !selectedProducts.includes(val));
        setProcesos(_procesos);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _proceso = { ...proceso };
        _proceso['category'] = e.value;
        setProceso(_proceso);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _proceso = { ...proceso };
        _proceso[`${name}`] = val;

        setProceso(_proceso);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _proceso = { ...proceso };
        _proceso[`${name}`] = val;

        setProceso(_proceso);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.proceso}
            </>
        );
    }

    const correoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.ksPersona.correoElectronico}
            </>
        );
    }

    const personaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {currencyFormat(rowData.costo)}
            </>
        );
    }

    const habilitadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Habilitado</span>
                <Checkbox  checked={rowData.habilitado} readOnly="true"/>
               
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`client-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProceso(rowData)} />
                <Button icon="pi pi-arrow-right" className="p-button-rounded mr-2" onClick={()=> history.push("/procesosMachote?idProceso="+rowData.id+"&proceso="+rowData.proceso)}/> 
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Creación de procesos de laboratorio</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && procesos ?
                    <DataTable ref={dt} value={procesos} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} trabajos"
                        globalFilter={globalFilter} emptyMessage="No se encontraron trabajos." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="proceso" header="Trabajo" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="costo" header="Costo total del proceso" sortable body={personaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="habilitado" header="Habilitado" sortable body={habilitadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                : <List/>}
                    <Dialog visible={procesoDialog} style={{ width: '450px' }} header="Detalle proceso" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Id del proceso: {proceso.id}</label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>
                        <div className="field">
                            <label htmlFor="proceso">Proceso del trabajo</label>
                            <InputText id="proceso" value={proceso.proceso} onChange={(e) => onInputChange(e, 'proceso')} required autoFocus className={classNames({ 'p-invalid': submitted && !proceso.proceso })} />
                            {submitted && !proceso.proceso && <Message severity="error" text="Proceso es requerido." />}
                        </div>
                        <div className="field">
                            <label htmlFor="costo">Costo total del proceso $</label>
                            <InputNumber value={proceso.costo} onValueChange={(e) => onInputChange(e, 'costo')} showButtons mode="decimal"></InputNumber>
                        </div> 
                        <div className="field-checkbox">
                                <Checkbox inputId="habilitado" name="option" value="Habilitado" checked={proceso.habilitado} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">Habilitado</label>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {proceso && <span>Are you sure you want to delete <b>{proceso.proceso}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {proceso && <span>Are you sure you want to delete the selected usuarios?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Procesos, comparisonFn);