import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { UsuariosService } from '../service/UsuariosService';
//import { MyLoader } from '../components/placeholders/MyLoader';
import { Password } from 'primereact/password';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import ContentLoader, {List} from "react-content-loader"
import { MultiSelect } from 'primereact/multiselect';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';

const Usuarios = () => {



    const updateMultiselect = (data) =>{
        
        usuario.ksUsuariosRoles = data;
        setUsuario(usuario);
        console.log(data);
        setRol(data);
        console.log(usuario);

    }

    const updateFieldListBox = (data) =>{
        try {

        usuario.ksPersona.id=data.id;
        usuario.ksPersona.nombreCompleto=data.nombreCompleto;
        usuario.ksPersona.nombre=data.nombre;
        usuario.ksPersona.correoElectronico=data.correoElectronico;

        setUsuario(usuario);
        console.log(data)
         console.log(usuario)

        setPersona(data)

            } catch (e) {
                console.log('Error')
            }


    }


    const [checkboxValue, setCheckboxValue] = useState([]);

 


    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setUsuario({
                ...usuario,
                ["habilitado"]:e.checked
            });

           
    };

    let emptyUsuario = {
        id: '',
        usuario: '',
        habilitado: true,
        contrasena: '',
        ksPersona: {
            id: '',
            nombreCompleto:'',
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            rfc: '',
            celular: '',
            correoElectronico: ''
        },
        ksUsuariosRoles:[{
            id: '',
            rol:''

        }]
       // image: 'foto.jpg',
       // tipoArchivo: '',
        //summitterId: '',
        //consecutivoArchivosGenerados: 0,
        //nombreArchivoRespuesta: '',
        //rating: 0,
        //nombreArchivo: ''
    };

    let emptyPersona= {
        id: '',
        nombreCompleto:'',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        rfc: '',
        celular: '',
        correoElectronico: ''
    }

    let emptyRol= {
        id: '',
        rol:''
    }

    const [usuarios, setUsuarios] = useState(null);
    const [personas, setPersonas] = useState(null);
    const [roles, setRoles] = useState([]);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [persona, setPersona] = useState(emptyPersona);
    const [rol, setRol] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);

    const usuariosService = new UsuariosService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                rolesModulosPorUsuarioService.mz(user.email,1).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        usuariosService.getAll().then(data => {setUsuarios(data.usuarios); setPersonas(data.personas); setRoles(data.roles);});
            
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option.rol}</span>
                </div>
            );
        }

        return 'Selecciona Roles';
    };

  const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.rol}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setUsuario(emptyUsuario);
        setPersona(emptyPersona);
        setRol(null);
        setSubmitted(false);
        setUsuarioDialog(true);
    }

    const hideDialog = () => {
        if (persona.nombreCompleto.trim())
            setPersonas(personas.filter((p) => p.id !== persona.id ))

        setSubmitted(false);
        setUsuarioDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveUsuario = () => {
        setSubmitted(true);

        if (usuario.usuario.trim() && usuario.contrasena.trim() && persona.nombreCompleto.trim()){
            let _usuarios = [...usuarios];
            let _usuario = { ...usuario };

            if (usuario.id) {
                const index = findIndexById(usuario.id);
                usuariosService.update(usuario);
                _usuarios[index] = _usuario;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'usuario actualizado', life: 3000 });
            }
            else {
                usuariosService.create(usuario).then(data => _usuario.id = data.id);
                //usuariosService.getAll().then(data => setUsuarios(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                _usuarios.push(_usuario);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'usuario creado', life: 3000 });
            }

            setUsuarios(_usuarios);
            setUsuarioDialog(false);
            setUsuario(emptyUsuario);
            setPersonas(personas.filter((p) => p.id !== persona.id ));
        }
    }

    const editUsuario = (usuarios) => {
        let _personas = [...personas];
        let _persona = { ...usuarios.ksPersona };
        _personas.push(_persona);
        setPersonas(_personas);

        setUsuario({ ...usuarios });
        setPersona({ ...usuarios.ksPersona });
        setRol(usuarios.ksUsuariosRoles);
        console.log(usuarios.ksUsuariosRoles)
        
        setUsuarioDialog(true);
    }

    const confirmDeleteProduct = (usuario) => {
        setUsuario(usuario);
        setDeleteClientDialog(true);
    }

    const deleteProduct = () => {
        let _usuarios = usuarios.filter(val => val.id !== usuario.id);
        setUsuarios(_usuarios);
        setDeleteClientDialog(false);
        setUsuario(emptyUsuario);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuario Deleted', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteClientsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _usuarios = usuarios.filter(val => !selectedProducts.includes(val));
        setUsuarios(_usuarios);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _usuario = { ...usuario };
        _usuario['category'] = e.value;
        setUsuario(_usuario);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.usuario}
            </>
        );
    }

    const correoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.ksPersona.correoElectronico}
            </>
        );
    }

    const personaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.ksPersona.nombreCompleto}
            </>
        );
    }

    const habilitadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Habilitado</span>
                <Checkbox  checked={rowData.habilitado} readOnly={true}/>
               
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`client-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUsuario(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage usuarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && usuarios ?
                    <DataTable ref={dt} value={usuarios} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} usuarios"
                        globalFilter={globalFilter} emptyMessage="No se encontraron usuarios." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="usuario" header="Usuario" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ksPersona.nombreCompleto" header="Nombre" sortable body={personaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ksPersona.correoElectronico" header="Correo" sortable body={correoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="habilitado" header="Habilitado" sortable body={habilitadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                : <List/>}
                    <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Detalle usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Id del usuario: {usuario.id}</label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>
                        <div className="field">
                            <label htmlFor="usuario">Usuario</label>
                            <InputText id="usuario" value={usuario.usuario} onChange={(e) => onInputChange(e, 'usuario')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.usuario })} />
                            {submitted && !usuario.usuario && <Message severity="error" text="Usuario es requerido." />}
                        </div>
                        <div className="field">
                            <label htmlFor="contrasena">Contraseña</label>
                            <Password id="contrasena" value={usuario.contrasena} onChange={(e) => onInputChange(e, 'contrasena')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.contrasena })} />
                            {submitted && !usuario.contrasena && <Message severity="error" text="Contraseña es requerida." />}
                        </div>
                        <div className="field-checkbox">
                                <Checkbox inputId="habilitado" name="option" value="Habilitado" checked={usuario.habilitado} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">Habilitado</label>
                        </div>

                        <div className="field">
                            <label htmlFor="roles">Roles</label>
                            <MultiSelect value={rol} onChange={(e) => updateMultiselect(e.value)}options={roles} optionLabel="rol" placeholder="Selecciona roles" filter
                        itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} />
                        </div>


                        <div className="field">
                            <label htmlFor="persona">Persona: {persona.nombreCompleto}</label>
                            <ListBox value={persona} onChange={(e) => updateFieldListBox(e.value)} options={personas} optionLabel="nombreCompleto" filter paginator rows={10} required/>
                            {submitted && !persona.nombreCompleto && <Message severity="error" text="Selecciona una persona." />}
                        </div>
                    {/*     <div className="field">
                            <label htmlFor="usuario">Usuario</label>
                            <InputTextarea id="usuario" value={usuario.usuario} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div> 
                        <div className="field">
                            <React.Fragment>
                            <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Image" className="mr-2 inline-block" />
                            </React.Fragment>
                        </div>

                        <div className="field">
                            <label className="mb-3">Category</label>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category1" name="category" value="Accessories" onChange={onCategoryChange} checked={usuario.category === 'Accessories'} />
                                    <label htmlFor="category1">Accessories</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category2" name="category" value="Clothing" onChange={onCategoryChange} checked={usuario.category === 'Clothing'} />
                                    <label htmlFor="category2">Clothing</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category3" name="category" value="Electronics" onChange={onCategoryChange} checked={usuario.category === 'Electronics'} />
                                    <label htmlFor="category3">Electronics</label>
                                </div>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="category4" name="category" value="Fitness" onChange={onCategoryChange} checked={usuario.category === 'Fitness'} />
                                    <label htmlFor="category4">Fitness</label>
                                </div>
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="price">Price</label>
                                <InputNumber id="price" value={usuario.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode="currency" currency="USD" locale="en-US" />
                            </div>
                            <div className="field col">
                                <label htmlFor="quantity">Quantity</label>
                                <InputNumber id="quantity" value={usuario.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} integeronly />
                            </div>

                        </div>
*/}

                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && <span>Are you sure you want to delete <b>{usuario.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && <span>Are you sure you want to delete the selected usuarios?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Usuarios, comparisonFn);