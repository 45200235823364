import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { VentasService } from '../service/ventas/VentasService';
import { AutoComplete } from 'primereact/autocomplete';
import { Password } from 'primereact/password';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import ContentLoader, {List} from "react-content-loader"
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { MultiSelect } from 'primereact/multiselect';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';


const Ventas = () => {

      const dropdownDientes = [
        'Superior',
        'Inferior'
    ];

    const dropdownDientesSuperior = [
        '18',
        '17',
        '16',
        '15',
        '14',
        '13',
        '12',
        '11',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28'
    ];

    const dropdownDientesInferior = [
        '48',
        '47',
        '46',
        '45',
        '44',
        '43',
        '42',
        '41',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38'
    ];
    
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoFilteredProceso, setAutoFilteredProceso] = useState([]);
    const [autoFilteredCliente, setAutoFilteredCliente] = useState([]);
    const [procesos, setProcesos] = useState(null);
    const [value7, setValue7] = useState(null);
    const [value8, setValue8] = useState(null);

    const updateFechaEntrega = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        venta.fechaEntrega = actualizaFecha;
        setVenta(venta);
        setValue8(data);
        //console.log(persona);

            } catch (e) {
            console.log('Error')
            }

    }

    const updateFechaIngreso = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        venta.fechaIngreso = actualizaFecha;
        setVenta(venta);
        setValue7(data);
        //console.log(persona);

            } catch (e) {
            console.log('Error')
            }

    }

    const onInputChangeEmpleado = (e) => {
        const val = (e.target && e.target.value) || '';
        let _venta = { ...venta };

        if (typeof val === "string") 
        {

         _venta["ksEmpleados"].ksPersona.nombreCompleto = val;
        }
        else
            _venta["ksEmpleados"] = val;


        //console.log(_cliente)
        setVenta(_venta);
    }

    const onInputChangeProceso = (e) => {
        const val = (e.target && e.target.value) || '';
        let _venta = { ...venta };

        if (typeof val === "string") 
        {
            _venta["ksProceso"].proceso = val;
            _venta["costo"] = 0;
        }
        else
        {
            _venta["ksProceso"] = val;
            _venta["costo"] = val.costo;
        }


        //console.log(_cliente)
        setVenta(_venta);
    }

    const onInputChangeCliente = (e) => {
        const val = (e.target && e.target.value) || '';
        let _venta = { ...venta };

        if (typeof val === "string") 
            _venta["ksCliente"].nombreComercial = val;
        else
            _venta["ksCliente"] = val;


        //console.log(_cliente)
        setVenta(_venta);
    }

    const searchProcesos = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < procesos.length; i++) {
            const pro = procesos[i];
            if (pro.proceso.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(pro);
            }
        }
        setAutoFilteredProceso(filtered);
    };

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }
    const history = useHistory();
    const [checkboxValue, setCheckboxValue] = useState([]);

 


    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setVenta({
                ...venta,
                ["habilitado"]:e.checked
            });

           
    };

    let emptyVenta = {
        id: '',
        ksProceso: {
            id: '',
            proceso: '',
            costo: 0},
        ksUsuarios: {
            id: '',
            usuario: ''},
        ksVentaPrincipal: {
            id: ''},
        fechaCreacion: '',
        status: 'REGISTRADO',
        archivo: '',
        costo: 0,
        observaciones: '',
        unidades: 0

    };


    const [ventas, setVentas] = useState(null);
    const [procesoDialog, setVentaDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [venta, setVenta] = useState(emptyVenta);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);
    const [multiselectValue, setMultiselectValue] = useState(null);

    const ventasService = new VentasService();
    const location = useLocation();
    const  idVentaPrincipal = JSON.parse(JSON.stringify(queryString.parse(location.search).idVentaPrincipal))
    const  ventaCliente = JSON.parse(JSON.stringify(queryString.parse(location.search).ventaCliente))
    const  ventaCosto = JSON.parse(JSON.stringify(queryString.parse(location.search).ventaCosto))
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                rolesModulosPorUsuarioService.mz(user.email,5).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        ventasService.getAll(idVentaPrincipal).then(data => {setVentas(data.ventas); setProcesos(data.procesos);});
        
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option}</span>
                </div>
            );
        }

        return 'Selecciona dientes';
    };

    const selectedInferiorItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option}</span>
                </div>
            );
        }

        return 'Selecciona dientes';
    };

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option}</span>
            </div>
        );
    };

    const itemTemplateInferior = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setVenta(emptyVenta);
        setValue7(null);
        setValue8(null);
        setSubmitted(false);
        setVentaDialog(true);
    }

    const hideDialog = () => {

        setSubmitted(false);
        setVentaDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveVenta = () => {
        setSubmitted(true);
        venta.ksUsuarios.usuario = user.email;
        venta.ksVentaPrincipal.id = idVentaPrincipal;

        if (venta.observaciones.trim()){
            let _ventas = [...ventas];
            let _venta = { ...venta };

            if (venta.id) {
                const index = findIndexById(venta.id);
                ventasService.update(venta);
                _ventas[index] = _venta;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'venta actualizada', life: 3000 });
                setVentas(_ventas);
                setVenta(emptyVenta);
            }
            else {
                ventasService.create(venta).then(data => {
                    _venta.id = data.id;
                    _ventas.push(_venta);
                    setVentas(_ventas);
                    setVenta(emptyVenta);
                });
                //usuariosService.getAll().then(data => setVentas(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'venta creada', life: 3000 });
            }

            
            setVentaDialog(false);
        }
    }

    const editVenta = (venta) => {
        var d =new  Date(venta.fechaIngreso);
        setValue7(d);
        var d2 =new  Date(venta.fechaEntrega);
        venta.ksVentaPrincipal.id = idVentaPrincipal;
        setValue8(d2);
        setVenta({ ...venta });
        
        setVentaDialog(true);
    }

    const confirmDeleteProduct = (venta) => {
        setVenta(venta);
        setDeleteClientDialog(true);
    }

    const deleteProduct = () => {

        ventasService.delete(venta.id);
        let _ventas = ventas.filter(val => val.id !== venta.id);
        setVentas(_ventas);
        setDeleteClientDialog(false);
        setVenta(emptyVenta);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Trabajo eliminado', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < ventas.length; i++) {
            if (ventas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteClientsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _procesos = ventas.filter(val => !selectedProducts.includes(val));
        setVentas(_procesos);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _proceso = { ...venta };
        _proceso['category'] = e.value;
        setVenta(_proceso);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _proceso = { ...venta };
        _proceso[`${name}`] = val;

        setVenta(_proceso);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _proceso = { ...venta };
        _proceso[`${name}`] = val;

        setVenta(_proceso);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">status</span>
                {rowData.status}
            </>
        );
    }

    const fechaCreacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaCreacion</span>
                {rowData.fechaCreacion}
            </>
        );
    }

    const fechaIngresoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaIngreso</span>
                {rowData.fechaIngreso}
            </>
        );
    }

    const fechaEntregaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaEntrega</span>
                {rowData.fechaEntrega}
            </>
        );
    }

    const fechaFinBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaFin</span>
                {rowData.fechaFin}
            </>
        );
    }

    const nombreComercialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nombreComercial</span>
                {rowData.ksCliente.nombreComercial}
            </>
        );
    }

    const nombreEmpleadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nombreCompleto</span>
                {rowData.ksEmpleados.ksPersona.nombreCompleto}
            </>
        );
    }

    const procesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {rowData.ksProceso.proceso}
            </>
        );
    }

    const inferiorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {rowData.dentaduraInferior}
            </>
        );
    }

    const usuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.ksUsuarios.usuario}
            </>
        );
    }


    const observacionesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">observaciones</span>
                {rowData.observaciones}
            </>
        );
    }

    const tipoTrabajoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">tipoTrabajo</span>
                {rowData.tipoTrabajo}
            </>
        );
    }

    const telefonoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">telefono</span>
                {rowData.telefono}
            </>
        );
    }

    const edadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">edad</span>
                {rowData.edad}
            </>
        );
    }

    const costoSugeridoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">constoSugerido</span>
                {currencyFormat(rowData.ksProceso.costo)}
            </>
        );
    }

    const costoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">costo</span>
                {currencyFormat(rowData.costo)}
            </>
        );
    }

    const unidadesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">unidades</span>
                {rowData.unidades}
            </>
        );
    }

    const abonosBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">abonos</span>
                {currencyFormat(rowData.abonos)}
            </>
        );
    }

    const saldoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">saldo</span>
                {currencyFormat(rowData.saldo)}
            </>
        );
    }

    const onUpload = (id) => {

        let _ventas = [...ventas];
                   
       const index = findIndexById(id);
       _ventas[index].archivo = "Archivo";
        setVentas(_ventas);
                       
                       
       toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Archivo actualizado', life: 3000 });

        //toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    }

    const archivoBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                
                <FileUpload mode="basic" name="demo[]" url={"http://kreativl.jvmhost.net/api-utils-1/UploadImage?idVenta="+rowData.id} 
                    accept="image/*" maxFileSize={10000000} 
                    onUpload={(e) => onUpload(rowData.id)}/>
                
            </div>
        );
    }

    const multiselectValues = [
        '48',
        '47',
        '46',
        '45',
        '44',
        '43',
        '42',
        '41',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38'
    ];

    const selecciondSuperiorBodyTemplate = (rowData) => {
        return (
            <div className="multiselect">

                
                <MultiSelect value={rowData.dentaduraSuperior} onChange={(e) => setMultiselectValue(e.value)} options={multiselectValues} optionLabel="name" placeholder="Selecciona dientes" filter
                        itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} disabled={true}/>
                
            </div>
        );
    }

    const selecciondientesBodyTemplate = (rowData) => {
        return (
            <div className="multiselect">

                
                <MultiSelect value={rowData.dentaduraInferior} onChange={(e) => setMultiselectValue(e.value)} options={multiselectValues} optionLabel="name" placeholder="Selecciona dientes" filter
                        itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} disabled={true}/>
                
            </div>
        );
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    const descargarArchivoBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.archivo ?
                    <Button icon="pi pi-download" className="p-button-rounded p-button-success mt-2"
                    onClick={() => openInNewTab("http://kreativl.jvmhost.net/api-utils-1/UploadFIEL?idVenta="+rowData.id)}/>
                : 
                    ""
                }
                
            </div>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editVenta(rowData)} />
                <Button icon="pi pi-arrow-right" className="p-button-rounded mr-2" onClick={()=> history.push("/procesosEtapas?idVenta="+rowData.id+"&ventaCliente="+ventaCliente+"&ventaCosto="+ventaCosto+"&idVentaPrincipal="+idVentaPrincipal+"&trabajo="+rowData.ksProceso.proceso)}/> 
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">No. trabajo: {idVentaPrincipal} cliente: {ventaCliente}</h5>
            <Button label="Ventas" icon="pi pi-arrow-left" className="p-button-rounded mr-2" onClick={()=> history.push("/ventasPrincipal")}/>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveVenta} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && ventas ?
                    <DataTable ref={dt} value={ventas} 
                        dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Trabajos"
                        globalFilter={globalFilter} emptyMessage="No hay trabajos registrados." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>

                        <Column body={actionBodyTemplate} header="Acciones" headerStyle={{ width: '20%', minWidth: '12rem' }}></Column>
                        <Column field="status" header="Status del proceso" sortable body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ksProceso.proceso" header="Trabajo" sortable body={procesoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        {/* <Column field="dentaduraInferior" header="Inferior" sortable body={inferiorBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column field="ksUsuarios.usuario" header="Usuario que crea el proceso" sortable body={usuarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ksProceso.costo" header="Costo sugerido" sortable body={costoSugeridoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="costo" header="Costo proceso" sortable body={costoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="unidades" header="Unidades" sortable body={unidadesBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="observaciones" header="Observaciones" sortable body={observacionesBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaCreacion" header="Fecha de creación" sortable body={fechaCreacionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={selecciondSuperiorBodyTemplate} header="Superior"></Column>
                        <Column body={selecciondientesBodyTemplate} header="Inferior"></Column>
                        
                        {/* <Column body={archivoBodyTemplate} header="Cargar archivo"></Column>
                        
                        <Column body={descargarArchivoBodyTemplate} header="Descargar archivo"></Column> */}
                    </DataTable>
                : <List/>}
                    <Dialog visible={procesoDialog} style={{ width: '450px' }} header={"Detalle venta "+venta.id} modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Status: {venta.status} </label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>

                        <div className="field">
                            <label htmlFor="id">Usuario ultima actualización: {venta.ksUsuarios.usuario}.</label>
                        </div>

                        <div className="field">
                            <label htmlFor="id">Fecha de creación: {venta.fechaCreacion}.</label>
                        </div>
                        <br></br>
                        <div className="field">
                            <span className="p-float-label">
                            <AutoComplete id="proceso" dropdown selected value={venta.ksProceso.proceso} onChange={(e) => onInputChangeProceso(e)} suggestions={autoFilteredProceso} completeMethod={searchProcesos} field="proceso"></AutoComplete>
                            <label htmlFor="proceso">Proceso {currencyFormat(venta.ksProceso.costo)} </label>
                            </span>
                                {submitted && !venta.ksProceso.proceso && <Message severity="error" text="Selecciona un proceso." />}
                        </div> 

                        <div className="field">
                            <label htmlFor="costo">Costo total del venta $</label>
                            <InputNumber value={venta.costo} onValueChange={(e) => onInputChange(e, 'costo')} showButtons mode="decimal"></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="unidades">Unidades</label>
                            <InputNumber value={venta.unidades} onValueChange={(e) => onInputChange(e, 'unidades')} showButtons></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="observaciones">Observaciones</label>
                            <InputTextarea id="observaciones" value={venta.observaciones} onChange={(e) => onInputChange(e, 'observaciones')} required autoFocus className={classNames({ 'p-invalid': submitted && !venta.observaciones })} />
                            {submitted && !venta.observaciones && <Message severity="error" text="observaciones es requerido." />}
                        </div>
                        <div className="multiselect">
                        <label htmlFor="entrega">Superior</label>
                            <MultiSelect value={venta.dentaduraSuperior} onChange={(e) => onInputChange(e, 'dentaduraSuperior')} options={dropdownDientesSuperior} optionLabel="name" placeholder="Selecciona dientes" filter
                                    itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} />
                        </div>

                        <div className="multiselect">
                        <label htmlFor="entrega">Inferior</label>
                            <MultiSelect value={venta.dentaduraInferior} onChange={(e) => onInputChange(e, 'dentaduraInferior')} options={dropdownDientesInferior} optionLabel="name" placeholder="Selecciona dientes" filter
                                    itemTemplate={itemTemplateInferior} selectedItemTemplate={selectedInferiorItemTemplate} />
                        </div>

                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {venta && <span>¿Seguro que deseas eliminar el trabajo<b>{venta.venta}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {venta && <span>Are you sure you want to delete the selected usuarios?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Ventas, comparisonFn);