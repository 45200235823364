import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';


export const LoginButton = () =>{
    const {loginWithRedirect} = useAuth0();

    return (
                    <li>
                      <button className="p-link layout-topbar-button" onClick={() => loginWithRedirect()}>
                        <i className="pi pi-sign-in"/>
                                        <span>Login</span>
                      </button>
                    </li>
    );
};


export default LoginButton;