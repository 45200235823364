import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { CuentasService } from '../service/contabilidad/CuentasService';
import { UsuariosService } from '../service/UsuariosService';
//import { MyLoader } from '../components/placeholders/MyLoader';
import { Password } from 'primereact/password';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import ContentLoader, {List} from "react-content-loader"
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';


const Cuentas = () => {

    const updateDropdown = (data) =>{
        
        cuenta.usuario = data.usuario;
        setCuenta(cuenta);
        setUsuario(data)
        console.log(cuenta)
        console.log(data)

    }

    const updateMultiselect = (data) =>{
        
        cuenta.ksUsuariosRoles = data;
        setCuenta(cuenta);
        console.log(data);
        //setRol(data);
        console.log(cuenta);

    }

    const updateFieldListBox = (data) =>{
        try {

        cuenta.ksPersona.id=data.id;
        cuenta.ksPersona.nombreCompleto=data.nombreCompleto;
        cuenta.ksPersona.nombre=data.nombre;
        cuenta.ksPersona.correoElectronico=data.correoElectronico;

        setCuenta(cuenta);
        console.log(data)
         console.log(cuenta)

        setPersona(data)

            } catch (e) {
                console.log('Error')
            }


    }


    const [checkboxValue, setCheckboxValue] = useState([]);

 


    const onCheckboxChange = (e, name) => {
       /*   let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

           alert(cuenta.habilitado);
 alert(cuenta.habilitado); */
            setCuenta({
                ...cuenta,
                [`${name}`]:e.checked
            });

           
    };

    let emptyCuenta = {
        id: '',
        cuenta: '',
        numeroCuenta: '',
        observaciones: '',
        esOrigen: true,
        esDestino: true,
        habilitado: true,
        usuario: ''
    };

    let emptyUsuario= {
        id: '',
        usuario:''
    }

    let emptyRol= {
        id: '',
        rol:''
    }

    const [cuentas, setCuentas] = useState(null);
    const [personas, setPersonas] = useState(null);
    const [roles, setRoles] = useState([]);
    const [cuentaDialog, setCuentaDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [cuenta, setCuenta] = useState(emptyCuenta);
    const [persona, setPersona] = useState(emptyUsuario);
    const [usuario, setUsuario] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState(null);

    const cuentasService = new CuentasService();
    const usuariosService = new UsuariosService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
        if(user) 
        {
            rolesModulosPorUsuarioService.mz(user.email,8).then(data =>{
                if(!data.samx)
                {
                    window.location.href = '/'
                }
                else
                {
                    cuentasService.getAll().then(data => {setCuentas(data); });
                    usuariosService.getAll().then(data => {setUsuarios(data.usuarios);});
           
                }
            });
            
        }

}, [isLoading, isAuthenticated]);


    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option.rol}</span>
                </div>
            );
        }

        return 'Selecciona Roles';
    };

  const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.rol}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setCuenta(emptyCuenta);
        setUsuario(emptyUsuario);
        //setRol(null);
        setSubmitted(false);
        setCuentaDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCuentaDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveUsuario = () => {
        setSubmitted(true);

        if (cuenta.cuenta.trim() && cuenta.numeroCuenta.trim() && cuenta.observaciones.trim()){
            let _cuentas = [...cuentas];
            let _cuenta = { ...cuenta };

            if (cuenta.id) {
                const index = findIndexById(cuenta.id);
                cuentasService.update(cuenta);
                _cuentas[index] = _cuenta;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'cuenta actualizado', life: 3000 });
            }
            else {
                cuentasService.create(cuenta).then(data => _cuenta.id = data.id);
                //cuentasService.getAll().then(data => setCuentas(data));
                //_cuenta.id = createId();
                //_cuenta.image = 'client-placeholder.svg';
                _cuentas.push(_cuenta);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'cuenta creado', life: 3000 });
            }

            setCuentas(_cuentas);
            setCuentaDialog(false);
            setCuenta(emptyCuenta);
        }
    }

    const editUsuario = (cuentas) => {
        let _cuentas = [...usuarios];
        let _usuario = usuario;
        //let _persona = { ...cuentas.ksPersona };
        //_personas.push(_persona);
        //setPersonas(_personas);

        const index = findIndexByUsuario(cuentas.usuario);
        _usuario = _cuentas[index];
        setUsuario(_usuario)
        setCuenta({ ...cuentas });
       // setPersona({ ...cuentas.ksPersona });
       // setRol(cuentas.ksUsuariosRoles);
       // console.log(cuentas.ksUsuariosRoles)
        
        setCuentaDialog(true);
    }

    const confirmDeleteProduct = (cuenta) => {
        setCuenta(cuenta);
        setDeleteClientDialog(true);
    }

    const deleteProduct = () => {
        let _cuentas = cuentas.filter(val => val.id !== cuenta.id);
        setCuentas(_cuentas);
        setDeleteClientDialog(false);
        setCuenta(emptyCuenta);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'cuenta Deleted', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < cuentas.length; i++) {
            if (cuentas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const findIndexByUsuario = (usuario) => {
        let index = -1;
        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].usuario === usuario) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteClientsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _cuentas = cuentas.filter(val => !selectedProducts.includes(val));
        setCuentas(_cuentas);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'cuentas Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _cuenta = { ...cuenta };
        _cuenta['category'] = e.value;
        setCuenta(_cuenta);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cuenta = { ...cuenta };
        _cuenta[`${name}`] = val;

        setCuenta(_cuenta);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _cuenta = { ...cuenta };
        _cuenta[`${name}`] = val;

        setCuenta(_cuenta);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const cuentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">cuenta</span>
                {rowData.cuenta}
            </>
        );
    }

    const usuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.usuario}
            </>
        );
    }

    const observacionesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.observaciones}
            </>
        );
    }

    const numeroCuentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">numeroCuenta</span>
                {rowData.numeroCuenta}
            </>
        );
    }

    const saldoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">saldo</span>
                {rowData.saldo}
            </>
        );
    }


    const habilitadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Habilitado</span>
                <Checkbox  checked={rowData.habilitado} readOnly={true}/>
               
            </>
        );
    }

    const esOrigenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">esOrigen</span>
                <Checkbox  checked={rowData.esOrigen} readOnly={true}/>
               
            </>
        );
    }

    const esDestinoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">esDestino</span>
                <Checkbox  checked={rowData.esDestino} readOnly={true}/>
               
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`client-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUsuario(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Adminsitración de cuentas</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={cuentas} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && cuentas && usuarios ?
                    <DataTable ref={dt} value={cuentas} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} cuentas"
                        globalFilter={globalFilter} emptyMessage="No se encontraron cuentas." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="cuenta" header="Cuenta" sortable body={cuentaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="numeroCuenta" header="Número de cuenta" sortable body={numeroCuentaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="observaciones" header="Observaciones" sortable body={observacionesBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="esOrigen" header="Es origen?" sortable body={esOrigenBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="esDestino" header="Es destino?" sortable body={esDestinoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="habilitado" header="Habilitado" sortable body={habilitadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="usuario" header="Usuario" sortable body={usuarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                : <List/>}
                    <Dialog visible={cuentaDialog} style={{ width: '450px' }} header="Detalle cuenta" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                      
                        <div className="field">
                            <label htmlFor="id">Id del cuenta: {cuenta.id}</label>
                        </div>
                        <div className="field">
                            <label htmlFor="cuenta">cuenta</label>
                            <InputText id="cuenta" value={cuenta.cuenta} onChange={(e) => onInputChange(e, 'cuenta')} required autoFocus className={classNames({ 'p-invalid': submitted && !cuenta.cuenta })} />
                            {submitted && !cuenta.cuenta && <Message severity="error" text="cuenta es requerido." />}
                        </div>
                        <div className="field">
                            <label htmlFor="numeroCuenta">Número de cuenta</label>
                            <InputText id="numeroCuenta" value={cuenta.numeroCuenta} onChange={(e) => onInputChange(e, 'numeroCuenta')} required autoFocus className={classNames({ 'p-invalid': submitted && !cuenta.numeroCuenta })} />
                            {submitted && !cuenta.numeroCuenta && <Message severity="error" text="Contraseña es requerida." />}
                        </div>
                        <div className="field">
                            <label htmlFor="observaciones">Observaciones</label>
                            <InputTextarea id="observaciones" value={cuenta.observaciones} onChange={(e) => onInputChange(e, 'observaciones')} required autoFocus className={classNames({ 'p-invalid': submitted && !cuenta.observaciones })} />
                            {submitted && !cuenta.observaciones && <Message severity="error" text="Contraseña es requerida." />}
                        </div>
                        <div className="field">
                            <label htmlFor="usuario">Usuarios</label>
                            <Dropdown value={usuario} 
                            onChange={(e) => updateDropdown(e.value)}  
                            options={usuarios} optionLabel="usuario" placeholder="Selecciona" />
                        </div>
                        <div className="field-checkbox">
                                <Checkbox inputId="esOrigen" name="option" value="esOrigen" checked={cuenta.esOrigen} onChange={(e) => onCheckboxChange(e, 'esOrigen')} />
                                <label htmlFor="esOrigen">Es cuenta Origen</label>
                        </div>
                        <div className="field-checkbox">
                                <Checkbox inputId="esDestino" name="option" value="esDestino" checked={cuenta.esDestino} onChange={(e) => onCheckboxChange(e, 'esDestino')} />
                                <label htmlFor="esDestino">Es cuenta destino</label>
                        </div>
                        <div className="field-checkbox">
                                <Checkbox inputId="habilitado" name="option" value="Habilitado" checked={cuenta.habilitado} onChange={(e) => onCheckboxChange(e, 'habilitado')} />
                                <label htmlFor="habilitado">Habilitado</label>
                        </div>

                   {/*      <div className="field">
                            <label htmlFor="roles">Roles</label>
                            <MultiSelect value={rol} onChange={(e) => updateMultiselect(e.value)}options={roles} optionLabel="rol" placeholder="Selecciona roles" filter
                        itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} />
                        </div>


                        <div className="field">
                            <label htmlFor="persona">Persona: {persona.nombreCompleto}</label>
                            <ListBox value={persona} onChange={(e) => updateFieldListBox(e.value)} options={personas} optionLabel="nombreCompleto" filter paginator rows={10} required/>
                            {submitted && !persona.nombreCompleto && <Message severity="error" text="Selecciona una persona." />}
                        </div> */}

                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cuenta && <span>Are you sure you want to delete <b>{cuenta.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cuenta && <span>Are you sure you want to delete the selected cuentas?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Cuentas, comparisonFn);