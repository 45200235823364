import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
//import {Inicio} from './components/Login'; 

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import Dashboard from './components/Dashboard';
import ButtonDemo from './components/ButtonDemo';
import ChartDemo from './components/ChartDemo';
import Documentation from './components/Documentation';
import FileDemo from './components/FileDemo';
import FloatLabelDemo from './components/FloatLabelDemo';
import FormLayoutDemo from './components/FormLayoutDemo';
import InputDemo from './components/InputDemo';
import ListDemo from './components/ListDemo';
import MenuDemo from './components/MenuDemo';
import MessagesDemo from './components/MessagesDemo';
import MiscDemo from './components/MiscDemo';
import OverlayDemo from './components/OverlayDemo';
import MediaDemo from './components/MediaDemo';
import PanelDemo from './components/PanelDemo';
import TableDemo from './components/TableDemo';
import TreeDemo from './components/TreeDemo';
import InvalidStateDemo from './components/InvalidStateDemo';
import BlocksDemo from './components/BlocksDemo';
import IconsDemo from './components/IconsDemo';
import {LoginButton} from './components/Login';
import {LogoutButton} from './components/Logout';
import { useAuth0 } from '@auth0/auth0-react';

import Crud from './pages/Crud';
import Usuarios from './administracion/Usuarios';
import Personas from './administracion/Personas';
import Empleados from './administracion/Empleados';
import Clientes from './administracion/Clientes';
import Procesos from './laboratorio/Procesos';
import Cuentas from './contabilidad/Cuentas';
import CuentasPorPagar from './contabilidad/CuentasPorPagar';
import Abonos from './contabilidad/Abonos';
import PagosComisiones from './contabilidad/PagosComisiones';
import ReportePagosComisiones from './contabilidad/ReportePagosComisiones';
import Ventas from './ventas/Ventas';
import ReporteVentas from './ventas/ReporteVentas';
import VentasPrincipal from './ventas/VentasPrincipal';
import ProcesosMachote from './laboratorio/ProcesosMachote';
import ProcesosEtapas from './laboratorio/ProcesosEtapas';
import ProcesosEtapasEmplSet from './laboratorio/ProcesosEtapasEmplSet';
import Proveedores from './compras/Proveedores'
import Compras from './compras/Compras'
import EmptyPage from './pages/EmptyPage';
import TimelineDemo from './pages/TimelineDemo';
import { RolesModulosPorUsuarioService } from './service/administracion/RolesModulosPorUsuarioService';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';

const App = () => {

    const menu = [
        // {
        //     label: 'Control', icon: 'pi pi-fw pi-search',
        //     items: [
        //         {
        //             label: 'Administración', icon: 'pi pi-fw pi-bookmark',
        //             items: [
		// 				//{ label: 'Lista de usuarios', icon: 'pi pi-fw pi-user-edit', to: '/usuarios', style: 'visibility: hidden'},
		// 				{ label: 'Lista de personas', icon: 'pi pi-fw pi-user', to: '/personas' },
		// 				{ label: 'Lista de empleados', icon: 'pi pi-fw pi-user', to: '/empleados' }
        //             ]
        //         }
        //     ]
        // }
        /* {
            label: 'Home',
            items: [{
                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'                
            }]
        },
        ,
        {
            label: 'Ventas', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Administración ventas', icon: 'pi pi-fw pi-bookmark',
                    items: [
						{ label: 'Lista de clientes', icon: 'pi pi-fw pi-user', to: '/clientes' },
						//{ label: 'Ordenes de trabajo', icon: 'pi pi-fw pi-briefcase', to: '/ventas' },
						{ label: 'Ordenes de trabajo', icon: 'pi pi-fw pi-briefcase', to: '/ventasPrincipal' }
                    ]
                }
            ]
        },
        {
            label: 'Laboratorio', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Laboratorio procesos', icon: 'pi pi-fw pi-bookmark',
                    items: [
						{ label: 'Procesos', icon: 'pi pi-fw pi-box', to: '/procesos' },
                        
						{ label: 'Ordenes en laboratorio', icon: 'pi pi-fw pi-th-large', to: '/procesosEtapasEmplSet?idProceso=1&proceso=proceso%201' }
                    ]
                }
            ]
        },
        {
            label: 'Contabilidad', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Cuentas', icon: 'pi pi-fw pi-bookmark',
                    items: [
						{ label: 'Alta de cuentas', icon: 'pi pi-fw pi-box', to: '/cuentas' },
                        
						{ label: 'Pagos comisión', icon: 'pi pi-fw pi-th-large', to: '/pagosComisiones' }
                    ]
                }
            ]
        },
        {
            label: 'UI Components', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
                { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
                { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
                { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
                { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
                { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
                { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
                { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
                { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
                { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
                { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
                { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
                { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
                { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
                { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
                { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
            ]
        },
        {
            label: 'UI Blocks',
            items: [
                { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "NEW" },
                { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-react' }
            ]
        },
        {
            label: 'Icons',
            items: [
                { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/icons' }
            ]
        },
        {
            label: 'Pages', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
                { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
                { label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
            ]
        },
        {
            label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {
                            label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
                            ]
                        },
                        {
                            label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
                            ]
                        },
                    ]
                },
                {
                    label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {
                            label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' },
                            ]
                        },
                        {
                            label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
                            ]
                        }
                    ]
                }
            ]
        } */
        // ,
        // {
        //     label: 'Get Started',
        //     items: [
        //         { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
        //         { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
        //     ]
        // }
    ];

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [rolesModUsu, setRolesModUsu] = useState(null);
    
    let emptyMen = {
        label: '',
        icon: '',
        items: [
            {
                label: "",
                icon: "",
                items: [
                ]
            }
        ]
    };

    let emptyMenVenta = {
        label: '',
        icon: '',
        items: [
            {
                label: "",
                icon: "",
                items: [
                ]
            }
        ]
    };

    let emptyMenLab = {
        label: '',
        icon: '',
        items: [
            {
                label: "",
                icon: "",
                items: [
                ]
            }
        ]
    };

    let emptyMenCon = {
        label: '',
        icon: '',
        items: [
            {
                label: "",
                icon: "",
                items: [
                ]
            }
        ]
    };

    let emptyItem = {
        label: "",
        icon: "",
        to: ""
    };

    let emptyItemCompras = {
            label: 'Administración de compras', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'General', icon: 'pi pi-fw pi-bookmark',
                    items: [
						{ label: 'Proveedores', icon: 'pi pi-fw pi-box', to: '/proveedores' },
                        
						{ label: 'Compras', icon: 'pi pi-fw pi-th-large', to: '/compras' },
                     
						{ label: 'Cuentas por pagar', icon: 'pi pi-fw pi-th-large', to: '/cuentasPorPagar' },
						{ label: 'Abonos', icon: 'pi pi-fw pi-th-large', to: '/abonos' }
                    ]
                }
            ]
        }

    const [menuRol, setMenuRol] = useState(null);
    const [men, setMen] = useState(emptyMen);
    const [menVenta, setMenVenta] = useState(emptyMenVenta);
    const [menLab, setMenLab] = useState(emptyMenLab);
    const [menCon, setMenCon] = useState(emptyMenCon);
    const [item, setItem] = useState(emptyItem);
    const [itemPersona, setItemPersona] = useState(emptyItem);
    const [itemEmpoleado, setItemEmpleado] = useState(emptyItem);
    const [itemCliente, setItemCliente] = useState(emptyItem);
    const [itemLab, setItemLab] = useState(emptyItem);
    const [itemCon, setItemCon] = useState(emptyItem);
    const [itemOT, setItemOT] = useState(emptyItem);
    const [itemOL, setItemOL] = useState(emptyItem);
    const [itemPC, setItemPC] = useState(emptyItem);
    const [itemRepVentas, setItemRepVentas] = useState(emptyItem);
    const [itemRepComisiones, setItemRepComisiones] = useState(emptyItem);

    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();
      
      
     
    useEffect(() => {
        if(!isLoading && !isAuthenticated) 
            loginWithRedirect();
        else
        {
            if(!isLoading) 
            {

                

                rolesModulosPorUsuarioService.getAll(user.email).then(data => {
                    setRolesModUsu(data.rolesModulosDTO);
                
                    let _menuRol = [ ...menu ];

                    if((data.rolesModulosDTO[0].moduloId === 1 && data.rolesModulosDTO[0].tieneRol) || (data.rolesModulosDTO[1].moduloId === 2 && data.rolesModulosDTO[1].tieneRol) || (data.rolesModulosDTO[2].moduloId === 3 && data.rolesModulosDTO[2].tieneRol))
                    {

                        men.label='Control';
                        men.icon='pi pi-fw pi-search';
                        men.items[0].label='Administración';
                        men.items[0].icon='pi pi-fw pi-bookmark';


                        if(data.rolesModulosDTO[0].moduloId === 1 && data.rolesModulosDTO[0].tieneRol)
                        {
                            let _item = { ...item};
                           
                            _item.label='Lista de usuarios';
                            _item.icon='pi pi-fw pi-user-edit';
                            _item.to='/usuarios';
                            men.items[0].items.push(_item);
                        }

                        if(data.rolesModulosDTO[1].moduloId === 2 && data.rolesModulosDTO[1].tieneRol)
                        {
                            let _itemPersona = { ...itemPersona};

                            _itemPersona.label='Lista de personas';
                            _itemPersona.icon='pi pi-fw pi-user';
                            _itemPersona.to='/personas';
                            men.items[0].items.push(_itemPersona);
                        }
                        
                        if(data.rolesModulosDTO[2].moduloId === 3 && data.rolesModulosDTO[2].tieneRol)
                        {
                            let _itemEmpoleado = { ...itemEmpoleado};
                            _itemEmpoleado.label='Lista de empleados';
                            _itemEmpoleado.icon='pi pi-fw pi-user';
                            _itemEmpoleado.to='/empleados';
                            men.items[0].items.push(_itemEmpoleado);
                        }
                       
                      _menuRol.push(men);
                    }
                    
                    if((data.rolesModulosDTO[3].moduloId === 4 && data.rolesModulosDTO[3].tieneRol) || (data.rolesModulosDTO[4].moduloId === 5 && data.rolesModulosDTO[4].tieneRol))
                    {

                        menVenta.label='Ventas';
                        menVenta.icon='pi pi-fw pi-search';
                        menVenta.items[0].label='Administración ventas';
                        menVenta.items[0].icon='pi pi-fw pi-bookmark';


                        if(data.rolesModulosDTO[3].moduloId === 4 && data.rolesModulosDTO[3].tieneRol)
                        {
                            let _itemCliente = { ...itemCliente};
                           
                            _itemCliente.label='Lista de clientes';
                            _itemCliente.icon='pi pi-fw pi-user';
                            _itemCliente.to='/clientes';
                            menVenta.items[0].items.push(_itemCliente);
                        }

                        if(data.rolesModulosDTO[4].moduloId === 5 && data.rolesModulosDTO[4].tieneRol)
                        {
                            let _itemOT = { ...itemOT};

                            _itemOT.label='Ordenes de trabajo';
                            _itemOT.icon='pi pi-fw pi-briefcase';
                            _itemOT.to='/ventasPrincipal';
                            menVenta.items[0].items.push(_itemOT);
                        }
                       
                      _menuRol.push(menVenta);
                    }

                    ///
                    if((data.rolesModulosDTO[5].moduloId === 6 && data.rolesModulosDTO[5].tieneRol) || (data.rolesModulosDTO[6].moduloId === 7 && data.rolesModulosDTO[6].tieneRol))
                    {

                        menLab.label='Laboratorio';
                        menLab.icon='pi pi-fw pi-search';
                        menLab.items[0].label='Laboratorio procesos';
                        menLab.items[0].icon='pi pi-fw pi-bookmark';


                        if(data.rolesModulosDTO[5].moduloId === 6 && data.rolesModulosDTO[5].tieneRol)
                        {
                            let _itemLab = { ...itemLab};
                           
                            _itemLab.label='Trabajos';
                            _itemLab.icon='pi pi-fw pi-box';
                            _itemLab.to='/procesos';
                            menLab.items[0].items.push(_itemLab);
                        }

                        if(data.rolesModulosDTO[6].moduloId === 7 && data.rolesModulosDTO[6].tieneRol)
                        {
                            let _itemOL = { ...itemOL};

                            _itemOL.label='Ordenes en laboratorio';
                            _itemOL.icon='pi pi-fw pi-th-large';
                            _itemOL.to='/procesosEtapasEmplSet';
                            menLab.items[0].items.push(_itemOL);
                        }
                       
                      _menuRol.push(menLab);
                    }


                    if((data.rolesModulosDTO[7].moduloId === 8 && data.rolesModulosDTO[7].tieneRol) || (data.rolesModulosDTO[8].moduloId === 9 && data.rolesModulosDTO[8].tieneRol))
                    {

                        menCon.label='Contabilidad';
                        menCon.icon='pi pi-fw pi-search';
                        menCon.items[0].label='Cuentas';
                        menCon.items[0].icon='pi pi-fw pi-bookmark';


                        if(data.rolesModulosDTO[7].moduloId === 8 && data.rolesModulosDTO[7].tieneRol)
                        {
                            let _itemCon = { ...itemCon};
                           
                            _itemCon.label='Alta de cuentas';
                            _itemCon.icon='pi pi-fw pi-box';
                            _itemCon.to='/cuentas';
                            menCon.items[0].items.push(_itemCon);
                        }

                        if(data.rolesModulosDTO[8].moduloId === 9 && data.rolesModulosDTO[8].tieneRol)
                        {
                            let _itemPC = { ...itemPC};

                            _itemPC.label='Pagos comisión';
                            _itemPC.icon='pi pi-fw pi-th-large';
                            _itemPC.to='/pagosComisiones';
                            menCon.items[0].items.push(_itemPC);
                        }

                        if(data.rolesModulosDTO[9].moduloId === 10 && data.rolesModulosDTO[9].tieneRol)
                        {
                            let _itemRepVentas = { ...itemRepVentas};

                            _itemRepVentas.label='Reporte Ventas';
                            _itemRepVentas.icon='pi pi-fw pi-th-large';
                            _itemRepVentas.to='/reporteVentas';
                            menCon.items[0].items.push(_itemRepVentas);
                        }

                        if(data.rolesModulosDTO[10].moduloId === 11 && data.rolesModulosDTO[10].tieneRol)
                        {
                            let _itemRepComisiones = { ...itemRepComisiones};

                            _itemRepComisiones.label='Reporte Pagos Comisiones';
                            _itemRepComisiones.icon='pi pi-fw pi-th-large';
                            _itemRepComisiones.to='/reportePagosComisiones';
                            menCon.items[0].items.push(_itemRepComisiones);
                        }
                       
                      _menuRol.push(menCon);
                    }
                    
                    _menuRol.push(emptyItemCompras);
                    setMenuRol(_menuRol);

                });
        
            }
               
        }

    }, [isLoading, isAuthenticated]);


    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    function Greeting(props) {
        const isLoggedIn = props.isLoggedIn;
       
          return { label: 'Lista de usuarios', icon: 'pi pi-fw pi-user-edit', to: '/usuarios' };
       
      }

    

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
      
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />



                        <div className="layout-sidebar" onClick={onSidebarClick}>
                        {!isLoading && rolesModUsu ?
                            
                            rolesModUsu[0].moduloId === 1 ?
                            //console.log(menuRol)
                                <AppMenu model={menuRol} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                                
                                : 
                                <AppMenu onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />    
                            
                            : 
                            <AppMenu onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />    
                        }
                        </div>
            
            
            <div className="layout-main-container">
                <div className="layout-main">
                    
              {/*   {isAuthenticated && !isLoading ?
            (<>
            <LogoutButton/>
      <script>
      function myFunction() {
          //alert("isLoading: " +isLoading+", isAuthenticated: " +isAuthenticated)
      }
      </script>
            </>)
            :(
                <>
                <LogoutButton/>
            <LoginButton/>
      <script>
      function myFunction() {
         //alert("isLoading: " +isLoading+", isAuthenticated: " +isAuthenticated)
      }
      </script>
            </>)} */}
                    <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} />
                    <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/media" component={MediaDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    <Route path="/blocks" component={BlocksDemo} />
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" render={() => <ChartDemo colorMode={layoutColorMode} location={location} />} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/usuarios" component={Usuarios} />
                    <Route path="/personas" component={Personas} />
                    <Route path="/empleados" component={Empleados} />
                    <Route path="/clientes" component={Clientes} />
                    <Route path="/procesos" component={Procesos} />
                    <Route path="/ventas" component={Ventas} />
                    <Route path="/ventasPrincipal" component={VentasPrincipal} />
                    <Route path="/procesosMachote" component={ProcesosMachote} />
                    <Route path="/procesosEtapas" component={ProcesosEtapas} />
                    <Route path="/procesosEtapasEmplSet" component={ProcesosEtapasEmplSet} />
                    <Route path="/cuentas" component={Cuentas} />
                    <Route path="/pagosComisiones" component={PagosComisiones} />
                    <Route path="/reportePagosComisiones" component={ReportePagosComisiones} />
                    <Route path="/reporteVentas" component={ReporteVentas} />
                    <Route path="/proveedores" component={Proveedores} />
                    <Route path="/compras" component={Compras} />
                    <Route path="/cuentasPorPagar" component={CuentasPorPagar} />
                    <Route path="/abonos" component={Abonos} />
                    {/* <Route path="/login" component={Login} /> */}
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/documentation" component={Documentation} />
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;
