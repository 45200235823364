import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ClientesService } from '../service/administracion/ClientesService';
import { AutoComplete } from 'primereact/autocomplete';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import { InputNumber } from 'primereact/inputnumber';
import ContentLoader, {List} from "react-content-loader";
import { CountryService } from '../service/CountryService';
import {Calendar} from 'primereact/calendar';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';


const Clientes = () => {


    const [value6, setValue6] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(null);

    const updateDropdown = (data) =>{
        
        empleado.ksDepartamentos = data;
        setEmpleado(empleado);
        console.log(data);
        setCliente(data);
        console.log(empleado);
    }

    const updateAutoCompleteClean = () =>{

        try {

       // cliente.ksEmpleados.id=data.id;
        cliente.ksEmpleados.ksPersona.nombreCompleto='';

        setCliente(cliente);
        //console.log(data)
        //console.log(empleado)

        //setPersona(data)

        } catch (e) {
            console.log('Error')
        }
}

    const updateAutoComplete = (data) =>{

        try {
         //   setTimeout(() => {
                
                
        //cliente.ksEmpleados.id=data.id;
        if (data.trim().length === 0)
        {
            
            cliente.ksEmpleados.ksPersona.nombreCompleto='';
            setAutoFilteredValue([...empleados]);
        }
        else
        {
            cliente.ksEmpleados.ksPersona.nombreCompleto=data;//data.ksPersona.nombreCompleto;
        }

        setCliente(cliente);

           // }, 250);

        } catch (e) {
            console.log('Error')
        }
}

    const updateFieldListBox = (data) =>{

        try {

        cliente.ksPersona.id=data.id;
        cliente.ksPersona.nombreCompleto=data.nombreCompleto;
        cliente.ksPersona.nombre=data.nombre;
        cliente.ksPersona.apellidoPaterno=data.apellidoPaterno;
        cliente.ksPersona.apellidoMaterno=data.apellidoMaterno;
        cliente.ksPersona.correoElectronico=data.correoElectronico;
        cliente.ksPersona.rfc=data.rfc;
        cliente.ksPersona.celular=data.celular;
        cliente.ksPersona.fechaNacimiento=data.fechaNacimiento;

        setCliente(cliente);
        //console.log(data)
        //console.log(empleado)

        setPersona(data)

        } catch (e) {
            console.log('Error')
        }

    }


    const [checkboxValue, setCheckboxValue] = useState([]);
    const [checkboxValueFechaA, setCheckboxValueFechaA] = useState([]);

    const onCheckboxChangeFechaA = (e) => {
        let selectedValue = [...checkboxValueFechaA];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValueFechaA(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setCliente({
                ...cliente,
                ["enviarCorreoAniversario"]:e.checked
            });

           
    };


    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setCliente({
                ...cliente,
                ["habilitado"]:e.checked
            });

           
    };

    let emptyEmpleado = {
        id: '',
        habilitado: true,
        ksPersona: {
            id: '',
            nombreCompleto:'',
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            rfc: '',
            celular: '',
            correoElectronico: '',
            fechaNacimiento: ''
        },
        ksDepartamentos:[{
            id: '',
            departamento:''

        }]
    };

    let emptyCliente = {
        id: '',
        razonSocial: '',
        contactoPuesto: '',
        correoContacto: '',
        nombreComercial: '',
        contactoTelefono: '',
        fehcaAniversario: '',
        cantacto:'',
        ciudad:'',
        cp:'',
        colonia:'',
        calle:'',
        numext:'',
        numint:'',
        dirconsultorio:'',
        descuento: 0,
        habilitado: true,
        enviarCorreoAniversario: true,
        ksPersona: {
            id: '',
            nombreCompleto:'',
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            rfc: '',
            celular: '',
            correoElectronico: '',
            fechaNacimiento: ''
        },
        ksEmpleados:{
            id: '',
            habilitado: true,
            ksPersona: {
                id: '',
                nombreCompleto:'',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                rfc: '',
                celular: '',
                correoElectronico: '',
                fechaNacimiento: ''
            }
        }
    };

    let emptyPersona= {
        id: '',
        nombreCompleto:'',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        rfc: '',
        celular: '',
        correoElectronico: ''
    }

    let emptyDepartamento= {
        id: '',
        departamento:''
    }

    const [empleados, setEmpleados] = useState(null);
    const [personas, setPersonas] = useState(null);
    const [clientes, setClientes] = useState(null);
    const [empleadoDialog, setClienteDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [empleado, setEmpleado] = useState(emptyEmpleado);
    const [persona, setPersona] = useState(emptyPersona);
    const [cliente, setCliente] = useState(emptyCliente);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);
    const [selectedAutoValue, setSelectedAutoValue] = useState(null);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(null);
    const [value7, setValue7] = useState(null);
    const [countries, setCountries] = useState([]);
    const [deleteProcesoDialog, setDeleteProcesoDialog] = useState(false);

    const clientesService = new ClientesService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                rolesModulosPorUsuarioService.mz(user.email,4).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        clientesService.getAll().then(data => {setEmpleados(data.empleados); setPersonas(data.personas); setClientes(data.clientes);});
           
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);
    
    const updateFechaAniversario = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        cliente.fehcaAniversario = actualizaFecha;
        setCliente(cliente);
        setValue7(data);
        //console.log(persona);

            } catch (e) {
            console.log('Error')
            }

    }

    const searchEmpleados = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < empleados.length; i++) {
            const empl = empleados[i];
            if (empl.ksPersona.nombreCompleto.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(empl);
            }
        }
        setAutoFilteredValue(filtered);
    };

    const openNew = () => {
        setEmpleado(emptyEmpleado);
        setPersona(emptyPersona);
        setCliente(emptyCliente);
        setSubmitted(false);
        setClienteDialog(true);
    }

    const hideDialog = () => {
		
        if (persona.nombreCompleto.trim())
            setPersonas(personas.filter((p) => p.id !== persona.id ))

        setSubmitted(false);
        setClienteDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProcesoDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveUsuario = () => {
        setSubmitted(true);
        
        let _clientes = [...clientes];
        let _cliente = { ...cliente };

        if (cliente.cantacto.trim() && persona.nombreCompleto.trim() && cliente.fehcaAniversario.trim()){

            if (cliente.id) {
                console.log(cliente)
                const index = findIndexById(cliente.id);
                clientesService.update(cliente);
                _clientes[index] = cliente;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'cliente actualizado', life: 3000 });
            }
            else {
                clientesService.create(cliente).then(data => _cliente.id = data.id);
                //ClientesService.getAll().then(data => setEmpleados(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                _clientes.push(_cliente);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'cliente creado', life: 3000 });
            }

            setClientes(_clientes);
            setClienteDialog(false);
            setEmpleado(emptyEmpleado);
            setCliente(emptyCliente);
            //setPersonas(personas.filter((p) => p.id !== persona.id ));
        }
    }

    const editCliente = (cliente) => {

        /* let _personas = [...personas];
        let _persona = { ...empleados.ksPersona };
        _personas.push(_persona);
        setPersonas(_personas); */
        console.log(cliente)
        setEmpleado({ ...cliente.ksEmpleados });
        setPersona({ ...cliente.ksPersona });
        var d =new  Date(cliente.fehcaAniversario);
        setValue7(d);
        setCliente(cliente);
        
        setClienteDialog(true);
    }

    const deleteProduct = () => {
        let _clientes = clientes.filter(val => val.id !== cliente.id);
        clientesService.delete(cliente.id);
        setClientes(_clientes);
        setDeleteProcesoDialog(false);
        setCliente(emptyCliente);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'empleado Deleted', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < clientes.length; i++) {
            if (clientes[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const deleteSelectedProducts = () => {
        let _usuarios = empleados.filter(val => !selectedProducts.includes(val));
        setEmpleados(_usuarios);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cliente = { ...cliente };
        _cliente[`${name}`] = val;

        setCliente(_cliente);
    }

    const onInputChangeEmpleadoId = (e) => {
        const val = (e.target && e.target.value) || '';

        var vals = val.split("-");
        let _cliente = { ...cliente };
        _cliente["ksEmpleados"].id = vals[1];
        console.log(vals)
        setCliente(_cliente);
    }

    const onInputChangeEmpleado = (e) => {
        const val = (e.target && e.target.value) || '';
        let _cliente = { ...cliente };

        if (typeof val === "string") 
            _cliente["ksEmpleados"].ksPersona.nombreCompleto = val;
        else
            _cliente["ksEmpleados"] = val;


        //console.log(_cliente)
        setCliente(_cliente);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const rfcBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.ksPersona.rfc}
            </>
        );
    }

    const celularBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.ksPersona.celular}
            </>
        );
    }

    const fechaNacimientoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.ksPersona.fechaNacimiento}
            </>
        );
    }

    const correoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.ksPersona.correoElectronico}
            </>
        );
    }

    const personaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.ksPersona.nombreCompleto}
            </>
        );
    }

    const ciudadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ciudad</span>
                {rowData.ciudad}
            </>
        );
    }

    const cpBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">cp</span>
                {rowData.cp}
            </>
        );
    }

    const coloniaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">colonia</span>
                {rowData.colonia}
            </>
        );
    }

    const calleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">calle</span>
                {rowData.calle}
            </>
        );
    }

    const numextBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">numext</span>
                {rowData.numext}
            </>
        );
    }

    const numintBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">numint</span>
                {rowData.numint}
            </>
        );
    }

    const dirconsultorioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">dirconsultorio</span>
                {rowData.dirconsultorio}
            </>
        );
    }

    const razonSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.razonSocial}
            </>
        );
    }

    const nombreComercialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.nombreComercial}
            </>
        );
    }

    const contactoPuestoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.contactoPuesto}
            </>
        );
    }

    const correoContactoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.correoContacto}
            </>
        );
    }

    const contactoTelefonoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.contactoTelefono}
            </>
        );
    }

    const fehcaAniversarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.fehcaAniversario}
            </>
        );
    }

    const descuentoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                %{rowData.descuento}
            </>
        );
    }

    const cantactoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.cantacto}
            </>
        );
    }

    const empleadoContactoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.ksEmpleados.ksPersona.nombreCompleto}
            </>
        );
    }

    const habilitadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Habilitado</span>
                <Checkbox  checked={rowData.habilitado} readOnly="true"/>
               
            </>
        );
    }

    const enviarCorreoAniversarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Enviar correo de anviversario</span>
                <Checkbox  checked={rowData.enviarCorreoAniversario} readOnly="true"/>
               
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCliente(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProcesosEtapa(rowData)} /> */}
            </div>
        );
    }

    const confirmDeleteProcesosEtapa = (cliente) => {
        setCliente(cliente);
        setDeleteProcesoDialog(true);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Administración de clientes</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && clientes ?
                    <DataTable ref={dt} value={clientes} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} clientes"
                        globalFilter={globalFilter} emptyMessage="No se encontraron clientes." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column body={actionBodyTemplate} header="Editar"headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="razonSocial" header="Razón social" sortable body={razonSocialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="nombreComercial" header="Nombre comercial" sortable body={nombreComercialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="cantacto" header="Vendedor" sortable body={cantactoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="contactoPuesto" header="Contacto puesto" sortable body={contactoPuestoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="correoContacto" header="Correo contacto" sortable body={correoContactoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="contactoTelefono" header="Correo teléfono" sortable body={contactoTelefonoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="fehcaAniversario" header="Fecha aniversario" sortable body={fehcaAniversarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="descuento" header="Descuento" sortable body={descuentoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="enviarCorreoAniversario" header="Enviar correo aniversario" sortable body={enviarCorreoAniversarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                        <Column hidden={true} field="ciudad" header="Ciudad" sortable body={ciudadBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="cp" header="Código Postal" sortable body={cpBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="colonia" header="Colonia" sortable body={coloniaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="calle" header="Calle" sortable body={calleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="numext" header="# Ext." sortable body={numextBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="numeint" header="# Int." sortable body={numintBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="dirconsultorio" header="Instituto/Consultorio" sortable body={dirconsultorioBodyTemplate} headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        
                        <Column hidden={true} field="ksPersona.nombreCompleto" header="Nombre" sortable body={personaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="ksPersona.correoElectronico" header="Correo" sortable body={correoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="ksPersona.rfc" header="RFC" sortable body={rfcBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="ksPersona.celular" header="Celular" sortable body={celularBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="ksPersona.fechaNacimiento" header="Fecha de nacimiento" sortable body={fechaNacimientoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="ksEmpleados.ksPersona.nombreCompleto" header="Empleado en contacto" sortable body={empleadoContactoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="habilitado" header="Habilitado" sortable body={habilitadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                    </DataTable>
                : <List/>}
                    <Dialog visible={empleadoDialog} style={{ width: '450px' }} header="Detalle cliente" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Id del empleado: {cliente.id}</label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>      
                       
                        <div className="field">
                            <label htmlFor="nombreComercial">Nombre comercial</label>
                            <InputText id="nombreComercial" value={cliente.nombreComercial} onChange={(e) => onInputChange(e, 'nombreComercial')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.nombreComercial })} />
                            {submitted && !cliente.nombreComercial && <Message severity="error" text="Nombre comercial es requerido." />}
                        </div>  
                         <div className="field">
                            <label htmlFor="razonSocial">Razón social</label>
                            <InputText id="razonSocial" value={cliente.razonSocial} onChange={(e) => onInputChange(e, 'razonSocial')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.razonSocial })} />
                            {submitted && !cliente.razonSocial && <Message severity="error" text="Razón social es requerido." />}
                        </div>  
                         <div className="field">
                            <label htmlFor="descuento">Descuento %</label>
                            <InputNumber value={cliente.descuento} onValueChange={(e) => onInputChange(e, 'descuento')} showButtons mode="decimal"
                            max={100}
                            min={0}
                            ></InputNumber>
                            
                        </div> 
                        <div className="field">
                            <label htmlFor="contacto">Vendedor</label>
                            <InputText id="cantacto" value={cliente.cantacto} onChange={(e) => onInputChange(e, 'cantacto')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.cantacto })} />
                            {submitted && !cliente.cantacto && <Message severity="error" text="Contacto es requerido." />}
                        </div>   
                        <div className="field">
                            <label htmlFor="contactoPuesto">Contacto de la clinica</label>
                            <InputText id="contactoPuesto" value={cliente.contactoPuesto} onChange={(e) => onInputChange(e, 'contactoPuesto')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.contactoPuesto })} />
                            {submitted && !cliente.contactoPuesto && <Message severity="error" text="Contacto puesto es requerido." />}
                        </div>         
                        <div className="field">
                            <label htmlFor="correoContacto">Correo clinica Doctor</label>
                            <InputText id="correoContacto" value={cliente.correoContacto} onChange={(e) => onInputChange(e, 'correoContacto')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.correoContacto })} />
                            {submitted && !cliente.correoContacto && <Message severity="error" text="Contacto correo es requerido." />}
                        </div>  
                        <div className="field">
                            <label htmlFor="contactoTelefono">Teléfono de clínica</label>
                            <InputText id="contactoTelefono" value={cliente.contactoTelefono} onChange={(e) => onInputChange(e, 'contactoTelefono')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.contactoTelefono })} />
                            {submitted && !cliente.contactoTelefono && <Message severity="error" text="Contacto teléfono es requerido." />}
                        </div>    




                        <div className="field">
                            <label htmlFor="ciudad">Ciudad</label>
                            <InputText id="ciudad" value={cliente.ciudad} onChange={(e) => onInputChange(e, 'ciudad')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.ciudad })} />
                            {submitted && !cliente.ciudad && <Message severity="error" text="Ciudad." />}
                        </div>         

                        <div className="field">
                            <label htmlFor="cp">Código Postal</label>
                            <InputText id="cp" value={cliente.cp} onChange={(e) => onInputChange(e, 'cp')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.cp })} />
                            {submitted && !cliente.cp && <Message severity="error" text="Código Postal." />}
                        </div>  
                        <div className="field">
                            <label htmlFor="colonia">Colonia</label>
                            <InputText id="colonia" value={cliente.colonia} onChange={(e) => onInputChange(e, 'colonia')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.colonia })} />
                            {submitted && !cliente.colonia && <Message severity="error" text="Colonia." />}
                        </div>  
                        <div className="field">
                            <label htmlFor="calle">Calle</label>
                            <InputText id="calle" value={cliente.calle} onChange={(e) => onInputChange(e, 'calle')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.calle })} />
                            {submitted && !cliente.calle && <Message severity="error" text="Calle." />}
                        </div>  
                        <div className="field">
                            <label htmlFor="numext"># Ext.</label>
                            <InputText id="numext" value={cliente.numext} onChange={(e) => onInputChange(e, 'numext')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.numext })} />
                            {submitted && !cliente.numext && <Message severity="error" text="# Ext." />}
                        </div>  
                        <div className="field">
                            <label htmlFor="numint"># Int.</label>
                            <InputText id="numint" value={cliente.numint} onChange={(e) => onInputChange(e, 'numint')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.numint })} />
                            {submitted && !cliente.numint && <Message severity="error" text="# Int." />}
                        </div>  
                        <div className="field">
                            <label htmlFor="dirconsultorio">Instituto/Consultorio</label>
                            <InputText id="dirconsultorio" value={cliente.dirconsultorio} onChange={(e) => onInputChange(e, 'dirconsultorio')} required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.dirconsultorio })} />
                            {submitted && !cliente.dirconsultorio && <Message severity="error" text="Instituto/Consultorio." />}
                        </div>  




                         <div className="field">
                                <label htmlFor="calendar">Fecha de aniversario</label>
                                <Calendar inputId="calendar" value={value7} onChange={(e) => updateFechaAniversario(e.value)}
                                dateFormat="mm/dd/yy"
                                ></Calendar>
                                {submitted && !cliente.fehcaAniversario && <Message severity="error" text="Fecha de aniversario es requerido." />}
                        </div>
                               
                        <div className="field-checkbox">
                                <Checkbox inputId="habilitado" name="option" value="Habilitado" checked={cliente.habilitado} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">Habilitado</label>
                        </div>                
                        <div className="field-checkbox">
                                <Checkbox inputId="enviarCorreoAniversario" name="option" value="enviarCorreoAniversario" checked={cliente.enviarCorreoAniversario} onChange={onCheckboxChangeFechaA} />
                                <label htmlFor="checkOption1">Enviar correo de aniversario</label>
                        </div>

                     
<br></br>
                        <div className="field">
                        <span className="p-float-label">
                        <AutoComplete id="autocomplete" dropdown selected value={cliente.ksEmpleados.ksPersona.nombreCompleto} onChange={(e) => onInputChangeEmpleado(e)} suggestions={autoFilteredValue} completeMethod={searchEmpleados} field="ksPersona.nombreCompleto"></AutoComplete>
                        <label htmlFor="autocomplete">Empleado</label>
                        </span>
                           {/*  <AutoComplete placeholder="Buscar" id="dd" dropdown value={cliente.ksEmpleados.ksPersona.nombreCompleto} 
                            onChange={(e) => onInputChange(e, 'ksEmpleados.ksPersona.nombreCompleto')}  
                            suggestions={autoFilteredValue} 
                            blurOnSelect={true}
                            completeMethod={searchCountry} 
                            field="ksPersona.nombreCompleto" 
                            /> */}
                            {submitted && !cliente.ksEmpleados.ksPersona.nombreCompleto && <Message severity="error" text="Selecciona un empleado." />}
                        </div> 


                        <div className="field">
                            <label htmlFor="persona">Persona: {persona.nombreCompleto}</label>
                            <ListBox value={persona} onChange={(e) => updateFieldListBox(e.value)} options={personas} optionLabel="nombreCompleto" filter paginator rows={10} required/>
                            {submitted && !persona.nombreCompleto && <Message severity="error" text="Selecciona una persona." />}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cliente && <span>Are you sure you want to delete <b>{cliente.nombreComercial}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cliente && <span>Are you sure you want to delete the selected usuarios?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Clientes, comparisonFn);