import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { PagosComisionesService } from '../service/contabilidad/PagosComisionesService';
import { List } from 'react-content-loader';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';
import { ProveedoresServices } from '../../src/service/ProveedoresServices';
import { ComprasServices } from '../../src/service/comprasServices';
import { Tag } from 'primereact/tag';

import { Calendar } from 'primereact/calendar';
import moment from 'moment';


const Compras = () => {




    let emptyPagosComision = {
        id: '',
        ksFormaPago: null,
        origenPago: '',
        fechaPago: null,
        importe: null,
        materia: null,
        ksCuentas: null,
        ksUsuarios: null,
        ksProveedor: null,
    };


    const [compras, setCompras] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [proveedor, setProveedor] = useState(null);
    const [proveedores, setProveedores] = useState(null);
    const [formasDePago, setFormasDePago] = useState([]);

    const [pagosComisionesDialog, setPagosComisionesDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [pagoComision, setPagoComision] = useState(emptyPagosComision);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [formaDePago, setFormaDePago] = useState(null);
    const [origenesPago, setOrigenesPago] = useState([]);
    const [origenPago, setOrigenPago] = useState(null);
    const [usuario, setUsuario] = useState(null);


    const [fechaDePago, setFechaDePago] = useState(null);

    const pagosComisionesService = new PagosComisionesService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();
    const proveedoreServices = new ProveedoresServices();
    const comprasServices = new ComprasServices();
    const {
        //isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
    } = useAuth0();

    useEffect(() => {
        if (user) {
            rolesModulosPorUsuarioService.mz(user.email, 9).then(data => {
                if (!data.samx) {
                    window.location.href = '/'
                }
                else {

                    proveedoreServices.getAll().then(data => {
                        setProveedores(data.proveedores);
                    })

                    comprasServices.getAll().then(data => {
                        setFormasDePago(data.formaPago)
                        setOrigenesPago(data.cuentasOrigen)
                        setCompras(data.compras)
                        setUsuarios(data.usuarios)
                    }).finally(() => {
                        setLoading(false);
                    })


                }
            }).catch((error) => {
                console.error(error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'El servidor dio un tropezon', life: 3000 });
            });

        }

    }, [isLoading, isAuthenticated]);





    const openNew = () => {
        setPagoComision(emptyPagosComision);
        setSubmitted(false);
        setPagosComisionesDialog(true);
    }

    const hideDialog = () => {

        setFechaDePago(null);
        setFormaDePago(null);
        setProveedor(null);
        setUsuario(null);
        setOrigenPago(null);
        setPagoComision(emptyPagosComision)
        setPagosComisionesDialog(false);
        setSubmitted(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveCompra = () => {
        setSubmitted(true);

        let now = moment().format('DD/MM/YYYY');

        if (pagoComision.id) {
            let requestBody = {
                "id": pagoComision.id,
                "ksCuentas": origenPago,
                "ksFormaPago": formaDePago,
                "ksProveedor": { id: proveedor },
                "ksUsuarios": usuario,
                "fechaPago": moment(fechaDePago).format('DD/MM/YYYY').toString(),
                "importe": pagoComision.importe,
                "concepto": pagoComision.concepto,
                "archivoExtension": null,
                "material": pagoComision.material,
                "proveedor": user.name,
                "sistemaPago": pagoComision.sistemaPago,
                "status": pagoComision.status
            }
            comprasServices.update(requestBody).then(data => {
                console.log(data);
                setPagosComisionesDialog(false);
                setLoading(true);

                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Compra Actualizada', life: 3000 });
                comprasServices.getAll().then(data => {
                    setFormasDePago(data.formaPago)
                    setOrigenesPago(data.cuentasOrigen)
                    setCompras(data.compras)
                    setUsuarios(data.usuarios)
                }).finally(() => {
                    setLoading(false);
                }).catch((error) => {
                    console.error(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El servidor dio un tropezon', life: 3000 });
                });

            }).catch((error) => {
                console.error(error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'El servidor dio un tropezon', life: 3000 });
            });


        } else {

            let requestBody = {
                "ksCuentas": origenPago,
                "ksFormaPago": formaDePago,
                "ksProveedor": { id: proveedor },
                "ksUsuarios": usuario,
                "fechaPago": moment(fechaDePago).format('DD/MM/YYYY').toString(),
                "fechaCreacion": now,
                "importe": pagoComision.importe,
                "concepto": pagoComision.concepto,
                "archivoExtension": null,
                "material": pagoComision.material,
                "proveedor": user.name,
                "sistemaPago": "CO",
                "status": "PENDIENTE"
            }


            comprasServices.create(requestBody).then(data => {
                setPagosComisionesDialog(false);
                setLoading(true);

                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Compra registrada', life: 3000 });
                comprasServices.getAll().then(data => {
                    setFormasDePago(data.formaPago)
                    setOrigenesPago(data.cuentasOrigen)
                    setCompras(data.compras)
                    setUsuarios(data.usuarios)
                }).finally(() => {
                    setLoading(false);
                }).catch((error) => {
                    console.error(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El servidor dio un tropezon', life: 3000 });
                });

            });

        }

    }

    const pagarComision = (pagoComisionParam) => {

        setLoading(true);
        setPagoComision(pagoComisionParam)
        setUsuario(pagoComisionParam.ksUsuarios)
        setFormaDePago(pagoComisionParam.ksFormaPago)
        setFechaDePago(moment(pagoComisionParam.fechaPago, "DD/MM/YYYY").toDate())
        setProveedor(pagoComisionParam.ksProveedor.id)
        setOrigenPago(pagoComisionParam.ksCuentas)
        setPagosComisionesDialog(true);
    }

    const confirmDeleteProduct = (pagoComision) => {
        setPagoComision(pagoComision);
        setDeleteClientDialog(true);
        comprasServices.update({ ...pagoComision, status: "CANCELADO" })
    }

    const deleteProduct = () => {
        setDeleteClientDialog(false);
        setPagoComision(emptyPagosComision);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'pagoComision Deleted', life: 3000 });
    }



    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const deleteSelectedProducts = () => {
        let _pagosComisiones = compras.filter(val => !selectedProducts.includes(val));
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'pagosComisiones Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _pagoComision = { ...pagoComision };
        _pagoComision['category'] = e.value;
        setPagoComision(_pagoComision);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '0';
        let _pagoComision = { ...pagoComision };
        switch (name) {
            case 'ksFormaPago':
                setFormaDePago(val)
                break;
            case 'ksProveedor':
                console.log(val);
                setProveedor(val)
                break;
            case 'ksfechaDePago':
                console.log(val);
                setFechaDePago(val)
                break;
            case 'ksCuentas':
                setOrigenPago(val);
                break;
            case 'fechaPago':
                console.log(val);
                setFechaDePago(val)
                break;
            case 'ksUsuarios':
                setUsuario(val)
                break;
            default:
                break;
        }
        _pagoComision[`${name}`] = val;
        setPagoComision(_pagoComision);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _pagoComision = { ...pagoComision };
        _pagoComision[`${name}`] = val;

        setPagoComision(_pagoComision);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }


    const comisionodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaCreacion</span>
                {rowData.fechaCreacion}
            </>
        );
    }

    const fechaPagoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaPago</span>
                {rowData.fechaPago}
            </>
        );
    }


    const empleadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">importe</span>
                {rowData.importe}
            </>
        );
    }

    const cuentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">cuenta</span>
                {rowData.ksCuentas.cuenta}
            </>
        );
    }


    const procesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {rowData.ksUsuarios.usuario}
            </>
        );
    }



    const actionBodyTemplate = (rowData) => {
        if (rowData.status !== "PAGADO") {
            return (
                <div className="actions">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => pagarComision(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-error mt-2" onClick={() => confirmDeleteProduct(rowData)} />
                </div>
            );
        } else {
            return (
                <div>
                    No disponibles
                </div>
            );

        }
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Administración de pagos de comisiones</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveCompra} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const ksFormaPagoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">formaPago</span>
                {rowData.ksFormaPago.formaPago}
            </>
        );
    }

    const materialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">material</span>
                {rowData.material}
            </>
        );
    }

    const sistemaPagoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">sistemaPago</span>
                {rowData.sistemaPago}
            </>
        );
    }

    const proveedorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Creado</span>
                {rowData.proveedor}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        switch (rowData.status) {
            case "CANCELADO":
                return (
                    <>
                        <span className="p-column-title">status</span>
                        <Tag value={rowData.status} icon="pi pi-check" severity="danger" />
                    </>
                );
            case "PENDIENTE":
                return (
                    <>
                        <span className="p-column-title">status</span>
                        <Tag value={rowData.status} icon="pi pi-exclamation-triangle" severity="info" />
                    </>
                );
                break;
            case "PAGADO":
                return (
                    <>
                        <span className="p-column-title">status</span>
                        <Tag value={rowData.status} icon="pi pi-check" severity="success" />
                    </>
                );
            default:
                break;
        }

    }

    const [selected, setSelected] = useState('Contado');


    return (

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    {!isLoading && compras ?
                        <DataTable ref={dt} value={compras}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} pagos de comisiones"
                            globalFilter={globalFilter} emptyMessage="No se encontraron pagos de comisiones." header={header} responsiveLayout="scroll">
                            <Column field="fechaPago" header="Fecha" sortable body={fechaPagoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="ksUsuarios.usuario" header="Fiador" sortable body={procesoBodyTemplate} headerStyle={{ width: '14%', minWidth: '20rem' }}></Column>
                            <Column field="ksCuentas.cuenta" header="ID CXP" sortable body={cuentaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="importe" header="Saldo inicial" sortable body={empleadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="fechaCreacion" header="Fecha de creacion" sortable body={comisionodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="material" header="Material" sortable body={materialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="ksFormaPago.formaPago" header="Forma de pago" sortable body={ksFormaPagoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="proveedor" header="Creado" sortable body={proveedorBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="sistemaPago" header="Sistema de pago" sortable body={sistemaPagoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="status" header="Estatus" sortable body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                            <Column body={actionBodyTemplate} header="Acciones" headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                        </DataTable>
                        : <List />}
                    <Dialog visible={pagosComisionesDialog} style={{ width: '450px' }} header="Detalle pagoComision" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="id">Id del pagoComision: {pagoComision.id}</label>

                        </div>



                        <div className="field">
                            {pagoComision.id ?
                                <div></div>
                                :
                                <div className="field">
                                    <label htmlFor="id">Sistema de pago</label>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="Contado" name="Contado" value="Contado" onChange={(e) => setSelected(e.value)} checked={selected === 'Contado'} />
                                        <label htmlFor="Contado" className="ml-2">Contado</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="Credito" name="Credito" value="Credito" onChange={(e) => setSelected(e.value)} checked={selected === 'Credito'} />
                                        <label htmlFor="Credito" className="ml-2">Credito</label>
                                    </div>
                                </div>


                            }

                        </div>


                        {selected === 'Contado' ?
                            <><div className="field">
                                <label htmlFor="empleado">Usuario</label>
                                <Dropdown value={usuario}
                                    onChange={(e) => onInputChange(e, 'ksUsuarios')}
                                    options={usuarios} optionLabel="usuario" placeholder="Selecciona" className={classNames({ 'p-invalid': submitted && !pagoComision.ksUsuarios })} />
                                {submitted && !pagoComision.ksUsuarios && <Message severity="error" text="Usuario es requerido." />}

                            </div><div className="field">
                                    <label htmlFor="cuentas">Formas de pago</label>
                                    <Dropdown value={formaDePago}
                                        onChange={(e) => onInputChange(e, 'ksFormaPago')}
                                        options={formasDePago} optionLabel="formaPago" placeholder="Selecciona" className={classNames({ 'p-invalid': submitted && !pagoComision.ksFormaPago })} />
                                    {submitted && !pagoComision.ksFormaPago && <Message severity="error" text="Forma de pago es requerido." />}
                                </div>
                                <div className="field">
                                    <label htmlFor="fechaPago">Fecha de pago</label>
                                    <Calendar value={fechaDePago}
                                        onChange={(e) => onInputChange(e, 'fechaPago')}
                                        className={classNames({ 'p-invalid': submitted && !pagoComision.fechaPago })}
                                    />
                                    {submitted && !pagoComision.fechaPago && <Message severity="error" text="Fecha de pago es requerido." />}

                                </div><div className="field">
                                    <label htmlFor="prooveedor">Proveedor</label>
                                    <Dropdown value={proveedor}
                                        onChange={(e) => onInputChange(e, 'ksProveedor')}
                                        options={proveedores} optionValue='id' optionLabel="proveedorNombre" placeholder="Selecciona" className={classNames({ 'p-invalid': submitted && !pagoComision.ksProveedor })} />
                                    {submitted && !pagoComision.ksProveedor && <Message severity="error" text="Proveedor es requerido." />}

                                </div>
                                <div className="field">
                                    <label htmlFor="importe">Importe</label>
                                    <InputText id="importe" type='number' value={pagoComision.importe} onChange={(e) => onInputChange(e, 'importe')} required autoFocus className={classNames({ 'p-invalid': submitted && !pagoComision.importe })} />
                                    {submitted && !pagoComision.importe && <Message severity="error" text="Importe es requerido." />}
                                </div>
                                <div className="field">
                                    <label htmlFor="material">Material</label>
                                    <InputText id="material" type='text' value={pagoComision.material} onChange={(e) => onInputChange(e, 'material')} required autoFocus className={classNames({ 'p-invalid': submitted && !pagoComision.material })} />
                                    {submitted && !pagoComision.material && <Message severity="error" text="material es requerido." />}
                                </div>
                                <div className="field">
                                    <label htmlFor="Concepto">Concepto</label>
                                    <InputTextarea id="concepto" value={pagoComision.concepto} onChange={(e) => onInputChange(e, 'concepto')} required autoFocus className={classNames({ 'p-invalid': submitted && !pagoComision.concepto })} />
                                    {submitted && !pagoComision.concepto && <Message severity="error" text="Cooncepto es requerido." />}
                                </div>
                                <div className="field">
                                    <label htmlFor="origenPago">Origen pago</label>
                                    <Dropdown value={origenPago}
                                        onChange={(e) => onInputChange(e, 'ksCuentas')}
                                        options={origenesPago} optionLabel="cuenta" placeholder="Selecciona" className={classNames({ 'p-invalid': submitted && !pagoComision.ksCuentas })} />
                                    {submitted && !pagoComision.ksCuentas && <Message severity="error" text="Origen de pago es requerido." />}
                                </div></> :
                            <div className="field">Credito form</div>
                        }

                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {pagoComision && <span>Are you sure you want to delete <b>{pagoComision.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {pagoComision && <span>Are you sure you want to delete the selected Comnpra?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Compras, comparisonFn);