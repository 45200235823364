import React from "react";
import{useAuth0} from "@auth0/auth0-react";

export const LogoutButton = () =>{

    const {logout} = useAuth0();

    return (
                    <li>
                      <button className="p-link layout-topbar-button" onClick={() => logout({returnTo: window.location.origin})}>
                        <i className="pi pi-sign-out"/>
                                        <span>logout</span>
                      </button>
                    </li>
        
    );
};

export default LogoutButton;