import axios from "axios";
const config = {
    headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMCwiaXNzIjoiS2FpemVudGVjTVgiLCJpYXQiOjE2NzYwMDU2OTgsInVzZXJuYW1lIjoic3VwZXJfdXN1YXJpbyJ9.SbzsJySna2kT9EFW1Bb9e5M2hbVx7cy47MpORppdzd0' }
};


export class RolesModulosPorUsuarioService{

    

    baseUrl="https://kreativlabserv.com.mx:11814/api/v3"
    //baseUrl="http://localhost:8080/v3"
    //http://localhost:8080/v3/calificaciones?alumno=1&rows=100
    
    // create(persona){
    //     return axios.post(this.baseUrl+"/personas",persona,config).then(res => res.data)
    // }

    // update(persona){
    //     return axios.put(this.baseUrl+"/personas",persona,config).then(res => res.data)
    // }

    // delete(id){
    //     return axios.delete(this.baseUrl+"/personas/"+id,config).then(res => res.data)
    // }

    getAll(usuario){
        return axios.get(this.baseUrl+"/rolesModulosPorUsuario?usuario="+usuario,config)
        .then(res => res.data)
    }

    mz(zms,ram){
        return axios.get(this.baseUrl+"/msz?zms="+zms+"&ram="+ram,config)
        .then(res => res.data)
    }
}