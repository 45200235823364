import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { VentasPrincipalService } from '../service/ventas/VentasPrincipalService';
import { VentasService } from '../service/ventas/VentasService';
import { AutoComplete } from 'primereact/autocomplete';
import { Password } from 'primereact/password';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import ContentLoader, {List} from "react-content-loader"
import { useHistory } from 'react-router-dom';
import {Calendar} from 'primereact/calendar';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';
import { MultiSelect } from 'primereact/multiselect';

const VentasPrincipal = () => {

    
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoFilteredProceso, setAutoFilteredProceso] = useState([]);
    const [autoFilteredCliente, setAutoFilteredCliente] = useState([]);
    const [empleados, setEmpleados] = useState(null);
    const [procesos, setProcesos] = useState(null);
    const [clientes, setClientes] = useState(null);
    const [value7, setValue7] = useState(null);
    const [value7Detalle, setValue7Detalle] = useState(null);
    const [value8, setValue8] = useState(null);
    const [value8Detalle, setValue8Detalle] = useState(null);

    const dropdownDientesSuperior = [
        '18',
        '17',
        '16',
        '15',
        '14',
        '13',
        '12',
        '11',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28'
    ];

    const dropdownDientesInferior = [
        '48',
        '47',
        '46',
        '45',
        '44',
        '43',
        '42',
        '41',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38'
    ];

    const itemTemplateInferior = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option}</span>
            </div>
        );
    };

    const selectedInferiorItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option}</span>
                </div>
            );
        }

        return 'Selecciona dientes';
    };

    const itemTemplateSuperior = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option}</span>
            </div>
        );
    };
	
	
const selectedItemTemplateSuperior = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option}</span>
                </div>
            );
        }

        return 'Selecciona dientes';
    };

    const updateFechaEntrega = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        venta.fechaEntrega = actualizaFecha;
        setVenta(venta);
        setValue8(data);
        //console.log(persona);

            } catch (e) {
            console.log('Error')
            }

    }

    const updateFechaIngreso = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        venta.fechaIngreso = actualizaFecha;
        setVenta(venta);
        setValue7(data);
        //console.log(persona);

            } catch (e) {
            console.log('Error')
            }

    }

    const onInputChangeEmpleado = (e) => {
        const val = (e.target && e.target.value) || '';
        let _venta = { ...venta };

        if (typeof val === "string") 
        {

         _venta["ksEmpleados"].ksPersona.nombreCompleto = val;
        }
        else
            _venta["ksEmpleados"] = val;


        //console.log(_cliente)
        setVenta(_venta);
    }


    const onInputChangeCliente = (e) => {
        const val = (e.target && e.target.value) || '';
        let _venta = { ...venta };

        if (typeof val === "string") 
            _venta["ksCliente"].razonSocial = val;
        else
        {
            //console.log(val)
            _venta["ksCliente"] = val;
            _venta["direcciondestino"] = (val.dircompleta) || '';
            _venta["telefono"] = val.contactoTelefono;
        }


        //console.log(_cliente)
        setVenta(_venta);
    }

    const searchClientes = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < clientes.length; i++) {
            const cli = clientes[i];
            if (cli.razonSocial.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(cli);
            }
        }
        setAutoFilteredCliente(filtered);
    };

    const searchEmpleados = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < empleados.length; i++) {
            const empl = empleados[i];
            if (empl.ksPersona.nombreCompleto.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(empl);
            }
        }
        setAutoFilteredValue(filtered);
    };

    const searchProcesos = (event) => {
        // in a real application, make a request to a remote url with the query and
        // return filtered results, for demo we filter at client side
        const filtered = [];
        const query = event.query;
        for (let i = 0; i < procesos.length; i++) {
            const pro = procesos[i];
            if (pro.proceso.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(pro);
            }
        }
        setAutoFilteredProceso(filtered);
    };

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }
    const history = useHistory();
    const [checkboxValue, setCheckboxValue] = useState([]);

 


    const onCheckboxChange = (e, paramName) => {
            setVenta({
                ...venta,
                [paramName]:e.checked
            });

           
    };
    let emptyVentaDetalle = {
        id: '',
        ksProceso: {
            id: '',
            proceso: '',
            costo: 0},
        ksUsuarios: {
            id: '',
            usuario: ''},
        ksVentaPrincipal: {
            id: ''},
        fechaCreacion: '',
        status: 'REGISTRADO',
        archivo: '',
        costo: 0,
        observaciones: '',
        unidades: 0

    };

    let emptyVenta = {
        id: '',
        ksCliente: {
            id: '',
            nombreComercial: '',
            contactoTelefono: '',
            razonSocial: ''},
        ksEmpleados: {
            id: '',
            ksPersona:{
                id: '',
                nombreCompleto: ''}},
        ksUsuarios: {
            id: '',
            usuario: ''},
        fechaCreacion: '',
        fechaIngreso: '',
        fechaEntrega: '',
        fechaFin: '',
        status: 'REGISTRADO',
        archivo: '',
        paciente: '',
        edad: 0,
        telefono: '',
        costo: 0,
        abonos: 0,
        saldo: 0,
        tipoTrabajo: '',
        observaciones: '',
        direcciondestino: '',
        unidades: 0,
        ventas: []
    };


    const [VentasPrincipal, setVentasPrincipal] = useState(null);
    const [ventas, setVentas] = useState(null);
    const [ventaDialog, setVentaDialog] = useState(false);
    const [ventaDialogDeatlle, setVentaDialogDetalle] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [venta, setVenta] = useState(emptyVenta);
    const [ventaDetalle, setVentaDetalle] = useState(emptyVentaDetalle);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [submittedDetalle, setSubmittedDetalle] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);

    const ventasService = new VentasService();
    const ventasPrincipalService = new VentasPrincipalService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                //console.log(user)
                //console.log(isLoading)
                rolesModulosPorUsuarioService.mz(user.email,5).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        ventasPrincipalService.getAll().then(data => {setVentasPrincipal(data.ventas);setEmpleados(data.empleados); setClientes(data.clientes);});
                        
        
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option.rol}</span>
                </div>
            );
        }

        return 'Selecciona Roles';
    };

  const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.rol}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        ventasService.getAll(0).then(data => {setVentas(data.ventas); setProcesos(data.procesos);});
        setVenta(emptyVenta);
        setValue7(null);
        setValue8(null);
        setSubmitted(false);
        setVentaDialog(true);
    }

    const openNewVenta = () => {
        setVentaDetalle(emptyVentaDetalle);
        setValue7Detalle(null);
        setValue8Detalle(null);
        setSubmittedDetalle(false);
        setVentaDialogDetalle(true);
    }

    const hideDialog = () => {

        setSubmitted(false);
        setVentaDialog(false);
    }

    const hideDialogdetalle = () => {

        setSubmittedDetalle(false);
        setVentaDialogDetalle(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    const saveVenta = () => {
        setSubmitted(true);
        venta.ksUsuarios.usuario = user.email;
        venta.ventas = ventas;

        if (venta.paciente.trim() && venta.fechaIngreso.trim() && venta.fechaEntrega.trim()){
            let _ventas = [...VentasPrincipal];
            let _venta = { ...venta };

            if (venta.id) {
                const index = findIndexById(venta.id);
                ventasPrincipalService.update(venta);
                _ventas[index] = _venta;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'venta actualizada', life: 3000 });
                setVentasPrincipal(_ventas);
                setVenta(emptyVenta);
            }
            else {
                //console.log(venta)
                //alert("si llega")
                ventasPrincipalService.create(venta).then(data => {
                    _venta.id = data.id;
                    _ventas.push(_venta);
                    setVentasPrincipal(_ventas);
                    setVenta(emptyVenta);
                });
                //usuariosService.getAll().then(data => setVentasPrincipal(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'venta creada', life: 3000 });
            }

            
            setVentaDialog(false);
        }
    }

    const saveVentaDetalle = () => {
        setSubmittedDetalle(true);
        ventaDetalle.ksUsuarios.usuario = user.email;
        ventaDetalle.ksVentaPrincipal.id = venta.id;

        if (ventaDetalle.observaciones.trim()){
            let _ventas = [...ventas];
            let _ventaDetalle = { ...ventaDetalle };
            let _venta = { ...venta };
            _venta.costo =_venta.costo + (_ventaDetalle.costo * _ventaDetalle.unidades)
            _venta.unidades =_venta.unidades + _ventaDetalle.unidades

            if (venta.id) {
                ventasService.create(ventaDetalle).then(data => {
                    _ventaDetalle.id = data.id;
                    _ventas.push(_ventaDetalle);
                    setVentas(_ventas);
                    setVentaDetalle(emptyVentaDetalle);
                    setVenta(_venta);
                });
                
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'trabajo agregado', life: 3000 });
            }
            else {
                //ventasService.create(venta).then(data => {
                    //_ventaDetalle.id = data.id;
                    _ventas.push(_ventaDetalle);
                    setVentas(_ventas);
                    setVentaDetalle(emptyVentaDetalle);
                    setVenta(_venta);
                //});
                
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'trabajo agregado', life: 3000 });
            }

            
            setVentaDialogDetalle(false);
        }
    }
	

    const editVenta = (venta) => {
        ventasService.getAll(venta.id).then(data => {setVentas(data.ventas); setProcesos(data.procesos);});
        var d =new  Date(venta.fechaIngreso);
        setValue7(d);
        var d2 =new  Date(venta.fechaEntrega);
        setValue8(d2);

        setVenta({ ...venta });
        
        setVentaDialog(true);
    }

    const confirmDeleteProduct = (ventaDetalle) => {
        setVentaDetalle(ventaDetalle);
        setDeleteClientDialog(true);
    }

    const deleteProduct = () => {
        //alert(ventaDetalle.unidades)
        
        let _venta = { ...venta };
        _venta.costo =_venta.costo - (ventaDetalle.costo * ventaDetalle.unidades)
        _venta.unidades =_venta.unidades - ventaDetalle.unidades
        setVenta(_venta);
        if(ventaDetalle.id)
        {
          //  alert(2)
            ventasService.delete(ventaDetalle.id);
            let _ventas = ventas.filter(val => val.id !== ventaDetalle.id);
            setVentas(_ventas);
        }
        else
        {
            //alert(3)
            let _ventas = ventas.filter(val => val.ksProceso.proceso !== ventaDetalle.ksProceso.proceso);
            setVentas(_ventas);
        }

        setDeleteClientDialog(false);
        setVentaDetalle(emptyVentaDetalle);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Trabajo eliminado', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < VentasPrincipal.length; i++) {
            if (VentasPrincipal[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const findIndexByIdDetalle = (id) => {
        let index = -1;
        for (let i = 0; i < ventas.length; i++) {
            if (ventas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteClientsDialog(true);
    }

    const deleteSelectedProducts = () => {
        let _procesos = VentasPrincipal.filter(val => !selectedProducts.includes(val));
        setVentasPrincipal(_procesos);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _proceso = { ...venta };
        _proceso['category'] = e.value;
        setVenta(_proceso);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _proceso = { ...venta };
        _proceso[`${name}`] = val;

        setVenta(_proceso);
    }

    const onInputChangeDetalle = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _proceso = { ...ventaDetalle };
        _proceso[`${name}`] = val;

        setVentaDetalle(_proceso);
    }
    
    const onInputChangeProceso = (e) => {
        const val = (e.target && e.target.value) || '';
        let _venta = { ...ventaDetalle };

        if (typeof val === "string") 
        {
            _venta["ksProceso"].proceso = val;
            _venta["costo"] = 0;
        }
        else
        {
            _venta["ksProceso"] = val;
            _venta["costo"] = val.costo;
        }


        //console.log(_cliente)
        setVentaDetalle(_venta);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _proceso = { ...venta };
        _proceso[`${name}`] = val;

        setVenta(_proceso);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const codeVentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">status</span>
                {rowData.status}
            </>
        );
    }

    const statusVentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">status</span>
                {rowData.status}
            </>
        );
    }

    const procesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {rowData.ksProceso.proceso}
            </>
        );
    }

    const fechaCreacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaCreacion</span>
                {rowData.fechaCreacion}
            </>
        );
    }

    const fechaIngresoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaIngreso</span>
                {rowData.fechaIngreso}
            </>
        );
    }

    const fechaEntregaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaEntrega</span>
                {rowData.fechaEntrega}
            </>
        );
    }

    const fechaFinBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">fechaFin</span>
                {rowData.fechaFin}
            </>
        );
    }

    const nombreComercialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nombreComercial</span>
                {rowData.ksCliente.razonSocial}
            </>
        );
    }

    const nombreEmpleadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nombreCompleto</span>
                {rowData.ksEmpleados.ksPersona.nombreCompleto}
            </>
        );
    }

  

    const usuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.ksUsuarios.usuario}
            </>
        );
    }

    const pacienteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">paciente</span>
                {rowData.paciente}
            </>
        );
    }

    const observacionesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">observaciones</span>
                {rowData.observaciones}
            </>
        );
    }

    const direcciondestinoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">direcciondestino</span>
                {rowData.direcciondestino}
            </>
        );
    }

    const tipoTrabajoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">tipoTrabajo</span>
                {rowData.tipoTrabajo}
            </>
        );
    }

    const telefonoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">telefono</span>
                {rowData.telefono}
            </>
        );
    }

    const edadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">edad</span>
                {rowData.edad}
            </>
        );
    }


    const costoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">costo</span>
                {currencyFormat(rowData.costo)}
            </>
        );
    }

    const unidadesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">unidades</span>
                {rowData.unidades}
            </>
        );
    }

    const unidadesBodyTemplateDetalle = (rowData) => {
        return (
            <>
                <span className="p-column-title">unidades</span>
                {rowData.unidades}
            </>
        );
    }

    const abonosBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">abonos</span>
                {currencyFormat(rowData.abonos)}
            </>
        );
    }

    const saldoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">saldo</span>
                {currencyFormat(rowData.saldo)}
            </>
        );
    }

    const onUpload = (id) => {

        let _ventas = [...VentasPrincipal];
                   
       const index = findIndexById(id);
       _ventas[index].archivo = "Archivo";
        setVentasPrincipal(_ventas);
                       
                       
       toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Archivo actualizado', life: 3000 });

        //toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    }

    const archivoBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                
                <FileUpload mode="basic" chooseLabel="Subir" name="demo[]" url={"https://kreativlabserv.com.mx/api-utils-1/UploadImage?idVentaPrincipal="+rowData.id} 
                    accept="image/*" maxFileSize={10000000} 
                    onUpload={(e) => onUpload(rowData.id)}/>
                
            </div>
        );
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    const descargarArchivoBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.archivo ?
                    <Button icon="pi pi-download" className="p-button-rounded p-button-success mt-2"
                    onClick={() => openInNewTab("https://kreativlabserv.com.mx/api-utils-1/UploadFIEL?idVentaPrincipal="+rowData.id)}/>
                : 
                    ""
                }
                
            </div>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editVenta(rowData)} />
                <Button icon="pi pi-arrow-right" className="p-button-rounded mr-2" onClick={()=> history.push("/ventas?idVentaPrincipal="+rowData.id+"&ventaCliente="+rowData.ksCliente.nombreComercial+"&ventaCosto="+rowData.costo)}/> 
            </div>
        );
    }

    const actionBodyTemplateDetalle = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />    
            </div>
        );
    }

        const pruebaceraBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">pruebacera</span>
                    <Checkbox  checked={rowData.pruebacera} readOnly={true}/>
                   
                </>
            );
        }

        const correcionBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">correcion</span>
                    <Checkbox  checked={rowData.correcion} readOnly={true}/>
                   
                </>
            );
        }
        
        const terminadoBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">terminado</span>
                    <Checkbox  checked={rowData.terminado} readOnly={true}/>
                   
                </>
            );
        }
        
        const sinterminarBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">terminado</span>
                    <Checkbox  checked={rowData.sinterminar} readOnly={true}/>
                   
                </>
            );
        }
        
        const modelotrabajoBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">modelotrabajo</span>
                    <Checkbox  checked={rowData.modelotrabajo} readOnly={true}/>
                   
                </>
            );
        }
        
        const modeloarticuladoBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">modeloarticulado</span>
                    <Checkbox  checked={rowData.modeloarticulado} readOnly={true}/>
                   
                </>
            );
        }
        
        const registromordidaBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">registromordida</span>
                    <Checkbox  checked={rowData.registromordida} readOnly={true}/>
                   
                </>
            );
        }
        
        const antagonistaBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">antagonista</span>
                    <Checkbox  checked={rowData.antagonista} readOnly={true}/>
                   
                </>
            );
        }
        
        const cucharillasupBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">cucharillasup</span>
                    <Checkbox  checked={rowData.cucharillasup} readOnly={true}/>
                   
                </>
            );
        }
        
        const cucharillainfBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">cucharillainf</span>
                    <Checkbox  checked={rowData.cucharillainf} readOnly={true}/>
                   
                </>
            );
        }
        
        const analogosBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">analogos</span>
                    <Checkbox  checked={rowData.analogos} readOnly={true}/>
                   
                </>
            );
        }
        
        const transferBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">transfer</span>
                    <Checkbox  checked={rowData.transfer} readOnly={true}/>
                   
                </>
            );
        }
        
        const aditamentosprotesicosBodyTemplate = (rowData) => {
            return (
                <>
                    <span className="p-column-title">aditamentosprotesicos</span>
                    <Checkbox  checked={rowData.aditamentosprotesicos} readOnly={true}/>
                   
                </>
            );
        }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Administración de ventas</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const ventaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveVenta} />
        </>
    );

    const ventaDetalleDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogdetalle} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveVentaDetalle} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && VentasPrincipal ?
            /*          <DataTable ref={dt} value={VentasPrincipal} 
                         dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                         currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} usuarios"
                         globalFilter={globalFilter} emptyMessage="No usuarios found." header={header} scrollDirection="scroll" scrollable> */
                         <DataTable ref={dt} value={VentasPrincipal} 
                         dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                         currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Ventas"
                         globalFilter={globalFilter} emptyMessage="Ventas no encontradas." header={header} responsiveLayout="scroll">
                         {/* <DataTable value={VentasPrincipal} scrollable scrollHeight="400px" scrollDirection="both" className="mt-3"> */}
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                      {/*   <Column field="id" header="No. Trabajo" body={codeBodyTemplate} sortable  frozen></Column> */}
                        <Column field="id" header="No. Trabajo" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="Status de la orden de trabajo" sortable body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ksCliente.razonSocial" header="Cliente" sortable body={nombreComercialBodyTemplate} headerStyle={{ width: '18%', minWidth: '10rem' }}></Column>
                        <Column field="ksUsuarios.usuario" header="Usuario" sortable body={usuarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                        <Column field="telefono" header="Teléfono del cliente" sortable body={telefonoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="ksEmpleados.ksPersona.nombreCompleto" header="Empleado" sortable body={nombreEmpleadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="costo" header="Costo venta" sortable body={costoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="unidades" header="Unidades" sortable body={unidadesBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="abonos" header="Total abonos" sortable body={abonosBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="saldo" header="Saldo" sortable body={saldoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}hidden={true}></Column>
                        <Column field="fechaCreacion" header="Fecha de creación" sortable body={fechaCreacionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="fechaIngreso" header="Fecha de pedido" sortable body={fechaIngresoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="fechaEntrega" header="Fecha de entrega" sortable body={fechaEntregaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="fechaFin" header="Fecha de finalización" sortable body={fechaFinBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        
                        <Column body={archivoBodyTemplate} header="Cargar archivo"></Column>
                        
                        <Column body={descargarArchivoBodyTemplate} header="Descargar archivo"></Column>

                       {/*  <Column field="tipoTrabajo" header="Tipo de trabajo" sortable body={tipoTrabajoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column> */}
                        <Column field="observaciones" header="Observaciones" sortable body={observacionesBodyTemplate} headerStyle={{ width: '25%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="direcciondestino" header="Dirección destino" sortable body={direcciondestinoBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="paciente" header="Nombre del paciente" sortable body={pacienteBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="edad" header="Edad del paciente" sortable body={edadBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}hidden={true}></Column>
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="pruebacera" header="Prueba de cera" sortable body={pruebaceraBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="correcion" header="Corrección" sortable body={correcionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="terminado" header="Terminado" sortable body={terminadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="sinterminar" header="Sin temrinar" sortable body={sinterminarBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="modelotrabajo" header="Modelo de trabajo" sortable body={modelotrabajoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="modeloarticulado" header="Modelo articulado" sortable body={modeloarticuladoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="registromordida" header="Registro de mordida" sortable body={registromordidaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="antagonista" header="Antagonista" sortable body={antagonistaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="cucharillasup" header="Cucharilla sup." sortable body={cucharillasupBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="cucharillainf" header="Cucharilla inf." sortable body={cucharillainfBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="analogos" header="Análogos" sortable body={analogosBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="transfer" header="Transfer" sortable body={transferBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="aditamentosprotesicos" header="Aditamentos protésicos" sortable body={aditamentosprotesicosBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        
                    </DataTable>
                : <List/>}
                    <Dialog visible={ventaDialog} style={{ width: '750px' }} header={"Detalle venta "+venta.id} modal className="p-fluid" footer={ventaDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Status: {venta.status} </label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>

                        <div className="field">
                            <label htmlFor="id">Usuario ultima actualización: {venta.ksUsuarios.usuario}.</label>
                        </div>

                        <div className="field">
                            <label htmlFor="id">Fecha de creación: {venta.fechaCreacion}.</label>
                        </div>


                        <br></br>
                        <div className="field">
                        <span className="p-float-label">
                        <AutoComplete id="empleado" dropdown selected value={venta.ksEmpleados.ksPersona.nombreCompleto} onChange={(e) => onInputChangeEmpleado(e)} suggestions={autoFilteredValue} completeMethod={searchEmpleados} field="ksPersona.nombreCompleto"></AutoComplete>
                        <label htmlFor="empleado">Vendedor</label>
                        </span>
                            {submitted && !venta.ksEmpleados.ksPersona.nombreCompleto && <Message severity="error" text="Selecciona un empleado." />}
                        </div> 

                        <br></br>
                        <div className="field">
                            <span className="p-float-label">
                            <AutoComplete id="cliente" dropdown selected value={venta.ksCliente.razonSocial} onChange={(e) => onInputChangeCliente(e)} suggestions={autoFilteredCliente} completeMethod={searchClientes} field="razonSocial"></AutoComplete>
                            <label htmlFor="cliente">Cliente</label>
                            </span>
                                {submitted && !venta.ksCliente.razonSocial && <Message severity="error" text="Selecciona un cliente." />}
                        </div> 

                        <div className="field">
                            <label htmlFor="paciente">Paciente</label>
                            <InputText id="paciente" value={venta.paciente} onChange={(e) => onInputChange(e, 'paciente')} required autoFocus className={classNames({ 'p-invalid': submitted && !venta.paciente })} />
                            {submitted && !venta.paciente && <Message severity="error" text="Paciente es requerido." />}
                        </div>



                        <div className="field">
                            <label htmlFor="edad">Edad del paciente</label>
                            <InputNumber value={venta.edad} onValueChange={(e) => onInputChange(e, 'edad')} showButtons mode="decimal"></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="telefono">Teléfono del cliente</label>
                            <InputText id="telefono" value={venta.telefono} onChange={(e) => onInputChange(e, 'telefono')} required autoFocus className={classNames({ 'p-invalid': submitted && !venta.telefono })} />
                            {submitted && !venta.telefono && <Message severity="error" text="Teléfono es requerido." />}
                        </div>

                        <br></br>
                        <Button label="Agregar nuevo trabajo" icon="pi pi-plus" className="p-button-success" onClick={openNewVenta} />
                            
                        <DataTable value={ventas} 
                         dataKey="id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                         currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Trabajos"
                         globalFilter={globalFilter} emptyMessage="No usuarios found." responsiveLayout="scroll">
                         {/* <DataTable value={VentasPrincipal} scrollable scrollHeight="400px" scrollDirection="both" className="mt-3"> */}
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column body={actionBodyTemplateDetalle} header="Acciones" headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                        <Column field="id" header="Id" sortable body={codeVentaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="status" header="Status del proceso" sortable body={statusVentaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ksProceso.proceso" header="Trabajo" sortable body={procesoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="unidades" header="Unidades" sortable body={unidadesBodyTemplateDetalle} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                    </DataTable>
                        
                        <div className="field">
                            <label htmlFor="observaciones">Observaciones</label>
                            <InputTextarea id="observaciones" value={venta.observaciones} onChange={(e) => onInputChange(e, 'observaciones')} required autoFocus className={classNames({ 'p-invalid': submitted && !venta.observaciones })} />
                            {submitted && !venta.observaciones && <Message severity="error" text="observaciones es requerido." />}
                        </div>

                        <div className="field">
                            <label htmlFor="direcciondestino">Dirección destino</label>
                            <InputTextarea id="direcciondestino" value={venta.direcciondestino} onChange={(e) => onInputChange(e, 'direcciondestino')} required autoFocus className={classNames({ 'p-invalid': submitted && !venta.direcciondestino })} />
                            {submitted && !venta.direcciondestino && <Message severity="error" text="Dirección destino." />}
                        </div>

                        {/* <div className="field">
                            <label htmlFor="tipoTrabajo">Tipo de trabajo</label>
                            <InputText id="tipoTrabajo" value={venta.tipoTrabajo} onChange={(e) => onInputChange(e, 'tipoTrabajo')} required autoFocus className={classNames({ 'p-invalid': submitted && !venta.tipoTrabajo })} />
                            {submitted && !venta.tipoTrabajo && <Message severity="error" text="Tipo de trabajo es requerido." />}
                        </div> */}

                        <div className="field">
                                <label htmlFor="ingreso">Fecha de ingreso</label>
                                <Calendar inputId="ingreso" value={value7} onChange={(e) => updateFechaIngreso(e.value)}
                                dateFormat="mm/dd/yy"
                                ></Calendar>
                                {submitted && !venta.fechaIngreso && <Message severity="error" text="Fecha de ingreso es requerido." />}
                        </div>
                        
                        <div className="field">
                                <label htmlFor="entrega">Fecha de entrega</label>
                                <Calendar inputId="entrega" value={value8} onChange={(e) => updateFechaEntrega(e.value)}
                                dateFormat="mm/dd/yy"
                                ></Calendar>
                                {submitted && !venta.fechaEntrega && <Message severity="error" text="Fecha de entrega es requerido." />}
                        </div>

                        
                        <br></br>
                        <div className="grid">
                            <div className="col-12 md:col-12">
                                <div className="card p-fluid">
                                <h5>Forma de entrega</h5>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="pruebacera" name="option" value="pruebacera" checked={venta.pruebacera} onChange={(e) => onCheckboxChange(e,"pruebacera")} />
                                            <label htmlFor="pruebacera">Prueba de cera</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="correcion" name="option" value="correcion" checked={venta.correcion} onChange={(e) => onCheckboxChange(e,"correcion")} />
                                            <label htmlFor="correcion">Correción</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="terminado" name="option" value="terminado" checked={venta.terminado} onChange={(e) => onCheckboxChange(e,"terminado")} />
                                            <label htmlFor="terminado">Terminado</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="sinterminar" name="option" value="sinterminar" checked={venta.sinterminar} onChange={(e) => onCheckboxChange(e,"sinterminar")} />
                                            <label htmlFor="sinterminar">Sin terminado</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="grid">
                            <div className="col-9 md:col-9">
                                <div className="card p-fluid">
                                <h5>Material proporcionado por el cliente</h5>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="modelotrabajo" name="option" value="modelotrabajo" checked={venta.modelotrabajo} onChange={(e) => onCheckboxChange(e,"modelotrabajo")} />
                                            <label htmlFor="modelotrabajo">Modelo de trabajo</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="modeloarticulado" name="option" value="modeloarticulado" checked={venta.modeloarticulado} onChange={(e) => onCheckboxChange(e,"modeloarticulado")} />
                                            <label htmlFor="modeloarticulado">Modelo articulado</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="registromordida" name="option" value="registromordida" checked={venta.registromordida} onChange={(e) => onCheckboxChange(e,"registromordida")} />
                                            <label htmlFor="registromordida">Registro de mordida</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="antagonista" name="option" value="antagonista" checked={venta.antagonista} onChange={(e) => onCheckboxChange(e,"antagonista")} />
                                            <label htmlFor="antagonista">Antagonista</label>
                                            </div>
                                        </div>


                                        
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="cucharillasup" name="option" value="cucharillasup" checked={venta.cucharillasup} onChange={(e) => onCheckboxChange(e,"cucharillasup")} />
                                            <label htmlFor="cucharillasup">Cucharilla sup.</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="cucharillainf" name="option" value="cucharillainf" checked={venta.cucharillainf} onChange={(e) => onCheckboxChange(e,"cucharillainf")} />
                                            <label htmlFor="cucharillainf">Cucharilla inf.</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="analogos" name="option" value="analogos" checked={venta.analogos} onChange={(e) => onCheckboxChange(e,"analogos")} />
                                            <label htmlFor="analogos">Análogos</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="transfer" name="option" value="transfer" checked={venta.transfer} onChange={(e) => onCheckboxChange(e,"transfer")} />
                                            <label htmlFor="transfer">Transfer</label>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="field-checkbox">
                                                <Checkbox inputId="aditamentosprotesicos" name="option" value="aditamentosprotesicos" checked={venta.aditamentosprotesicos} onChange={(e) => onCheckboxChange(e,"aditamentosprotesicos")} />
                                            <label htmlFor="aditamentosprotesicos">Aditamentos protésicos</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="field">
                            <label htmlFor="costo">Costo total del venta $</label>
                            <InputNumber value={venta.costo} onValueChange={(e) => onInputChange(e, 'costo')} showButtons mode="decimal" ></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="unidades">Unidades</label>
                            <InputNumber value={venta.unidades} onValueChange={(e) => onInputChange(e, 'unidades')} showButtons readOnly={true}></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="abonos">Total de abonos $</label>
                            <InputNumber value={venta.abonos} onValueChange={(e) => onInputChange(e, 'abonos')} showButtons mode="decimal"></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="saldo">Saldo $</label>
                            <InputNumber value={venta.saldo} onValueChange={(e) => onInputChange(e, 'saldo')} showButtons mode="decimal"></InputNumber>
                        </div> 

                    </Dialog>

                   



                    <Dialog visible={ventaDialogDeatlle} style={{ width: '450px' }} header={"Detalle venta "+ventaDetalle.id} modal className="p-fluid" footer={ventaDetalleDialogFooter} onHide={hideDialogdetalle}>
                       
                        <br></br>
                        <div className="field">
                            <span className="p-float-label">
                            <AutoComplete id="proceso" dropdown selected value={ventaDetalle.ksProceso.proceso} onChange={(e) => onInputChangeProceso(e)} suggestions={autoFilteredProceso} completeMethod={searchProcesos} field="proceso"></AutoComplete>
                            <label htmlFor="proceso">Proceso {currencyFormat(ventaDetalle.ksProceso.costo)} </label>
                            </span>
                                {submittedDetalle && !ventaDetalle.ksProceso.proceso && <Message severity="error" text="Selecciona un proceso." />}
                        </div> 

                        <div className="field">
                            <label htmlFor="costo">Costo del trabajo $</label>
                            <InputNumber value={ventaDetalle.costo} onValueChange={(e) => onInputChangeDetalle(e, 'costo')} showButtons mode="decimal"></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="unidades">Unidades</label>
                            <InputNumber value={ventaDetalle.unidades} onValueChange={(e) => onInputChangeDetalle(e, 'unidades')} showButtons></InputNumber>
                        </div> 

                        <div className="field">
                            <label htmlFor="observaciones">Observaciones</label>
                            <InputTextarea id="observaciones" value={ventaDetalle.observaciones} onChange={(e) => onInputChangeDetalle(e, 'observaciones')} required autoFocus className={classNames({ 'p-invalid': submittedDetalle && !ventaDetalle.observaciones })} />
                            {submittedDetalle && !ventaDetalle.observaciones && <Message severity="error" text="observaciones es requerido." />}
                        </div>
                        <div className="multiselect">
                        <label htmlFor="entrega">Superior</label>
                            <MultiSelect value={ventaDetalle.dentaduraSuperior} onChange={(e) => onInputChangeDetalle(e, 'dentaduraSuperior')} options={dropdownDientesSuperior} optionLabel="name" placeholder="Selecciona dientes" filter
                                    itemTemplate={itemTemplateSuperior} selectedItemTemplate={selectedItemTemplateSuperior} />
                        </div>

                        <div className="multiselect">
                        <label htmlFor="entrega">Inferior</label>
                            <MultiSelect value={ventaDetalle.dentaduraInferior} onChange={(e) => onInputChangeDetalle(e, 'dentaduraInferior')} options={dropdownDientesInferior} optionLabel="name" placeholder="Selecciona dientes" filter
                                    itemTemplate={itemTemplateInferior} selectedItemTemplate={selectedInferiorItemTemplate} />
                        </div>

                    </Dialog>


                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {venta && <span>¿Seguro que deseas eliminar el trabajo?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {venta && <span>Are you sure you want to delete the selected usuarios?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(VentasPrincipal, comparisonFn);