import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProcesosEtapasService } from '../service/laboratorio/ProcesosEtapasService';
import { AutoComplete } from 'primereact/autocomplete';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import { InputNumber } from 'primereact/inputnumber';
import ContentLoader, {List} from "react-content-loader";
import { CountryService } from '../service/CountryService';
import {Calendar} from 'primereact/calendar';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';


const ProcesosEtapas = () => {

    const history = useHistory();
    const paramsString = "q=URLUtils.searchParams&topic=api";
    const params = new URLSearchParams(window.location.search);
    

    const dropdownValues = [
        'Recepción',
        'Yeso',
        'Diseño',
        'Calidad',
        'Maquillaje',
        'Mensajería'
    ];

    const updateDropdown = (data) =>{
        
        departamento.ksDepartamentos = data;
        setDepartamento(departamento);
        console.log(data);
        setProcesoEtapa(data);
        console.log(departamento);
    }

    const updateAutoCompleteClean = () =>{

        try {

       // procesoEtapa.ksEmpleados.id=data.id;
        //procesoEtapa.ksEmpleados.ksPersona.nombreCompleto='';

        //setProcesoEtapa(procesoEtapa);
        //console.log(data)
        //console.log(departamento)

        //setProceso(data)

        } catch (e) {
            console.log('Error')
        }
}

    const updateAutoComplete = (data) =>{

        try {
         //   setTimeout(() => {
                
                
        procesoEtapa.ksProceso.id=data.id;
        if (data.trim().length === 0)
        {
            
            procesoEtapa.ksProceso.proceso='';
            setAutoFilteredValue([...procesos]);
        }
        else
        {
            procesoEtapa.ksProceso.proceso=data.proceso;
            procesoEtapa.ksProceso.proceso=data.costo;
        }

        setProcesoEtapa(procesoEtapa);

           // }, 250);

        } catch (e) {
            console.log('Error')
        }
}

    const updateFieldListBox = (data) =>{

        try {

         procesoEtapa.ksDepartamentos.id=data.id;
         procesoEtapa.ksDepartamentos.departamento=data.departamento;

        setProcesoEtapa(procesoEtapa);

        setDepartamento(data)

        } catch (e) {
            console.log('Error')
        }

    }


    const [checkboxValue, setCheckboxValue] = useState([]);
    const [checkboxValueFechaA, setCheckboxValueFechaA] = useState([]);

    const onCheckboxChangeFechaA = (e) => {
        let selectedValue = [...checkboxValueFechaA];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValueFechaA(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setProcesoEtapa({
                ...procesoEtapa,
                ["enviarCorreoAniversario"]:e.checked
            });

           
    };


    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

            //let idP = Number(idVenta);
            //let _procesos =[...procesos]
            //const index = findProcesoById(idP);
            //let costo =_procesos[index].costo;

            if(e.checked)
            {
                setProcesoEtapa({
                    ...procesoEtapa,
                    ["calculadoPorcentaje"]:e.checked,
                    ["comision"]:(ventaCosto*procesoEtapa.porcentaje)/100
                });
            }
            else
            {
                setProcesoEtapa({
                    ...procesoEtapa,
                    ["calculadoPorcentaje"]:e.checked
            });
            }

           
    };

    const onInputChangePorcentaje = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _procesoEtapa = { ...procesoEtapa };
        _procesoEtapa[`${name}`] = val;

        //let idP = Number(idVenta);
        //let _procesos =[...procesos]
        //const index = findIndexById(procesoEtapa.id);
        //let costo =_procesos[index].costo;

        _procesoEtapa.comision = (ventaCosto*_procesoEtapa.porcentaje)/100
        setProcesoEtapa(_procesoEtapa);
    }


    let emptyProcesoEtapa = {
        id: '',
        costo: 0,
        etapa: '',
        comision: 0,
        capacidadPorUnidad: 0,
        capacidadPorTrabajo: 0,
        calculadoPorcentaje: true,
        porcentaje:0,
        numeroOrden: 0,
        fechaCreacion: '',
        archivo: '',
        ksVentas:{
            id: '',
            costo: 0
        },
        ksDepartamentos:{
            id: '',
            departamento: ''
        }
    };

    let emptyProceso= {
        id: '',
        costo:0,
        proceso: ''
    }

    let emptyDepartamento= {
        id: '',
        departamento:''
    }

    const [departamentos, setDepartamentos] = useState(null);
    const [procesos, setProcesos] = useState(null);
    const [procesosEtapas, setProcesosEtapas] = useState(null);
    const [procesoMachoteDialog, setProcesoEtapaDialog] = useState(false);
    const [deleteProcesoDialog, setDeleteProcesoDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [departamento, setDepartamento] = useState(emptyDepartamento);
    const [proceso, setProceso] = useState(emptyProceso);
    const [procesoEtapa, setProcesoEtapa] = useState(emptyProcesoEtapa);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);
    const [selectedAutoValue, setSelectedAutoValue] = useState(null);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(null);
    const [value7, setValue7] = useState(null);

    const procesosEtapasService = new ProcesosEtapasService();
    const location = useLocation();
    const  trabajo = JSON.parse(JSON.stringify(queryString.parse(location.search).trabajo))
    const  idVenta = JSON.parse(JSON.stringify(queryString.parse(location.search).idVenta))
    const  idVentaPrincipal = JSON.parse(JSON.stringify(queryString.parse(location.search).idVentaPrincipal))
    const  ventaCliente = JSON.parse(JSON.stringify(queryString.parse(location.search).ventaCliente))
    const  ventaCosto = JSON.parse(JSON.stringify(queryString.parse(location.search).ventaCosto))
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                rolesModulosPorUsuarioService.mz(user.email,5).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        procesosEtapasService.getAll(idVenta).then(data => {setDepartamentos(data.departamentos); setProcesosEtapas(data.procesoEtapas);});
         
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);

    const updateFechaAniversario = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        procesoEtapa.fehcaAniversario = actualizaFecha;
        setProcesoEtapa(procesoEtapa);
        setValue7(data);
        //console.log(proceso);

            } catch (e) {
            console.log('Error')
            }

    }

    const searchCountry = (event) => {
       // setTimeout(() => {
            if (!event.query.trim().length) {

                
                setAutoFilteredValue([...procesos]);
                
            }
            else {
                  setAutoFilteredValue(procesos.filter((e) => {
                      return e.proceso.toLowerCase().startsWith(event.query.toLowerCase());
                  }));
            }
       // }, 50);
    };

    const openNew = () => {

        const numeroOrdenes = procesosEtapas.map(object => {
            return object.numeroOrden;
          });

                emptyProcesoEtapa.numeroOrden = numeroOrdenes.length + 1;
                emptyProcesoEtapa.ksVentas.id = idVenta;
                emptyProcesoEtapa.status = "PENDIENTE";
                emptyProcesoEtapa.usuario = user.email;
        //let idP = Number(idVenta);
        //let _procesos =[...procesos]
        //const index = findProcesoById(idP);
        //emptyProcesoEtapa.ksVentas.costo = ventaCosto;
        //setProcesoEtapa(procesoEtapa); 
        setDepartamento(emptyDepartamento);
        //setProceso(emptyProceso);
        setProcesoEtapa(emptyProcesoEtapa);
        setSubmitted(false);
        setProcesoEtapaDialog(true);
    }

    const hideDialog = () => {

        setSubmitted(false);
        setProcesoEtapaDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProcesoDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }

    const saveUsuario = () => {
        setSubmitted(true);
        let _procesosEtapa = [...procesosEtapas];
        let _procesoEtapa = { ...procesoEtapa };

        if (procesoEtapa.etapa.trim() && procesoEtapa.ksDepartamentos.departamento.trim()){

            if (procesoEtapa.id) {
                console.log(procesoEtapa)
                const index = findIndexById(procesoEtapa.id);
                procesosEtapasService.update(procesoEtapa);
                _procesosEtapa[index] = procesoEtapa;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'proceso etapa actualizado', life: 3000 });
            }
            else {
                procesosEtapasService.create(procesoEtapa).then(data => _procesoEtapa.id = data.id);
                //procesosEtapasService.getAll().then(data => setDepartamentos(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                _procesosEtapa.push(_procesoEtapa);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'proceso etapa creado', life: 3000 });
            }

            setProcesosEtapas(_procesosEtapa);
            setProcesoEtapaDialog(false);
            setDepartamento(emptyDepartamento);
            setProcesoEtapa(emptyProcesoEtapa);
            //setProcesos(procesos.filter((p) => p.id !== proceso.id ));
        }
    }

    const editProcesoEtapa = (procesoEtapa) => {
        setProcesoEtapa(procesoEtapa);        

        setDepartamento({ ...procesoEtapa.ksDepartamentos });
        
        setProcesoEtapaDialog(true);
    }

    const deleteProduct = () => {
        let _procesosEtapa = procesosEtapas.filter(val => val.id !== procesoEtapa.id);
        
        procesosEtapasService.delete(procesoEtapa.id);
        setProcesosEtapas(_procesosEtapa);
        setDeleteProcesoDialog(false);
        setProcesoEtapa(emptyProcesoEtapa);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Proceso eliminado', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < procesosEtapas.length; i++) {
            if (procesosEtapas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const findProcesoById = (id) => {
        let index = -1;
        for (let i = 0; i < procesos.length; i++) {
            if (procesos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const deleteSelectedProducts = () => {
        let _usuarios = departamentos.filter(val => !selectedProducts.includes(val));
        setDepartamentos(_usuarios);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
         const val = (e.target && e.target.value) || '';
         let _procesoEtapa = { ...procesoEtapa };
         _procesoEtapa[`${name}`] = val;

         setProcesoEtapa(_procesoEtapa);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }


    const capacidadPorTrabajoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">capacidadPorTrabajo</span>
                {rowData.capacidadPorTrabajo}
            </>
        );
    }

    const capacidadPorUnidadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">capacidadPorUnidad</span>
                {rowData.capacidadPorUnidad}
            </>
        );
    }

    const etapaSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">etapa</span>
                {rowData.etapa}
            </>
        );
    }

    const idVentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">idVenta</span>
                {rowData.ksVentas.id}
            </>
        );
    }

    const numeroOrdenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">numeroOrden</span>
                {rowData.numeroOrden}
            </>
        );
    }

    const costoTotalProcesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">costoTotalProceso</span>
                {currencyFormat(rowData.ksVentas.costo)}
            </>
        );
    }

    const departamentoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">departamento</span>
                {rowData.ksDepartamentos.departamento}
            </>
        );
    }


    const comisionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">comision</span>
                {currencyFormat(rowData.comision)}
            </>
        );
    }

    const porcentajeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">porcentaje</span>
                %{rowData.porcentaje}
            </>
        );
    }

    const procesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {rowData.proceso}
            </>
        );
    }

    const usuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.usuario}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">status</span>
                {rowData.status}
            </>
        );
    }

    const calculadoPorcentajeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">calculadoPorcentaje</span>
                <Checkbox  checked={rowData.calculadoPorcentaje} readOnly="true"/>
               
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProcesoEtapa(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProcesosEtapa(rowData)} />
            </div>
        );
    }



    const confirmDeleteProcesosEtapa = (procesoEtapa) => {
        setProcesoEtapa(procesoEtapa);
        setDeleteProcesoDialog(true);
    }

    const header = (
        

        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">No. trabajo: {idVentaPrincipal} cliente: {ventaCliente} Trabajo: {trabajo}</h5>
            <Button label="Regresar a trabajos" icon="pi pi-arrow-left" className="p-button-rounded mr-2" onClick={()=> history.push("/ventas?idVentaPrincipal="+idVentaPrincipal+"&ventaCliente="+ventaCliente+"&ventaCosto="+ventaCosto)}/> 
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && procesosEtapas ?
                    <DataTable ref={dt} value={procesosEtapas} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} procesos"
                        globalFilter={globalFilter} emptyMessage="No hay procesos." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} hidden={true}></Column>
                        <Column field="etapa" header="Etapa" sortable body={etapaSocialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="proceso" header="Proceso" sortable body={procesoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="usuario" header="Usuario asignado" sortable body={usuarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="status" header="Status estapa" sortable body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      
                        <Column hidden={true} field="comision" header="Comisión" sortable body={comisionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true}field="porcentaje" header="%" sortable body={porcentajeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="numeroOrden" header="Número de orden del proceso" sortable body={numeroOrdenBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                        <Column hidden={true} field="calculadoPorcentaje" header="Calculado por %" sortable body={calculadoPorcentajeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="capacidadPorUnidad" header="CPU" sortable body={capacidadPorUnidadBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column hidden={true} field="capacidadPorTrabajo" header="CPT" sortable body={capacidadPorTrabajoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                        <Column hidden={true} field="ksVentas.id" header="ID venta" sortable body={idVentaBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      {/* <Column field="ksVentas.costo" header="Costo Total" sortable body={costoTotalProcesoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        */}
                        <Column field="ksDepartamentos.departamento" header="Departamento" sortable body={departamentoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                         
                    </DataTable>
                : <List/>}
                    <Dialog visible={procesoMachoteDialog} style={{ width: '450px' }} header="Detalle procesos etapas" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Id del proceso etapa: {procesoEtapa.id}</label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>      
                        <div className="field">
                            <label htmlFor="ventaId">Id Venta: {procesoEtapa.ksVentas.id} </label>
                            {/* <AutoComplete placeholder="Buscar" id="dd" dropdown value={procesoEtapa.ksProceso.proceso} 
                            onChange={(e) => updateAutoComplete(e.value)}  
                            suggestions={autoFilteredValue} 
                            blurOnSelect={true}
                            completeMethod={searchCountry} 
                            field="proceso" 
                            readOnly true
                            />
                            {submitted && !procesoEtapa.ksEmpleados.ksPersona.nombreCompleto && <Message severity="error" text="Selecciona un departamento." />} */}
                        </div> 
                        <div className="field">
                            <label htmlFor="costo">Costo total de la venta: {currencyFormat(parseInt(ventaCosto))}</label>
                        </div>   
                       
                        <div className="field">
                            <label htmlFor="etapa">Etapa</label>
                            <Dropdown value={procesoEtapa.etapa} onChange={(e) => onInputChange(e, 'etapa')} options={dropdownValues}  placeholder="Select" />
                            {/* <InputText id="etapa" value={procesoEtapa.etapa} onChange={(e) => onInputChange(e, 'etapa')} required autoFocus className={classNames({ 'p-invalid': submitted && !procesoEtapa.etapa })} /> */}
                            {submitted && !procesoEtapa.etapa && <Message severity="error" text="Etapa es requerido." />}
                        </div>  

                        <div className="field">
                            <label htmlFor="proceso">Proceso</label>
                            <InputText id="proceso" value={procesoEtapa.proceso} onChange={(e) => onInputChange(e, 'proceso')} required autoFocus className={classNames({ 'p-invalid': submitted && !procesoEtapa.proceso })} />
                            {submitted && !procesoEtapa.proceso && <Message severity="error" text="Proceso es requerido." />}
                        </div>  

                        <div className="field">
                            <label htmlFor="comision">Comisión $</label>
                            <InputNumber value={procesoEtapa.comision} onValueChange={(e) => onInputChange(e, 'comision')} showButtons mode="decimal"
                            disabled = {procesoEtapa.calculadoPorcentaje}
                            ></InputNumber>
                        </div> 
                        
                        <div className="field">
                            <label htmlFor="porcentaje">Porcentaje de comisión %</label>
                            <InputNumber value={procesoEtapa.porcentaje} onValueChange={(e) => onInputChangePorcentaje(e, 'porcentaje')} showButtons mode="decimal"
                            max={100}
                            min={0}
                            disabled = {!procesoEtapa.calculadoPorcentaje}
                            ></InputNumber>
                            
                        </div> 

                        
                        <div className="field-checkbox">
                                <Checkbox inputId="calculadoPorcentaje" name="option" value="Es calculadop por %" checked={procesoEtapa.calculadoPorcentaje} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">Es calculado por % del costo total {currencyFormat(procesoEtapa.ksVentas.costo)}</label>
                        </div>  

                        <div className="field">
                            <label htmlFor="numeroOrden"># Orden</label>
                            <InputNumber value={procesoEtapa.numeroOrden} onValueChange={(e) => onInputChange(e, 'numeroOrden')} showButtons mode="decimal"></InputNumber>
                        </div>  
                        
                        <div className="field">
                            <label htmlFor="capacidadPorUnidad">Capacidad por unidad(CPU)</label>
                            <InputNumber value={procesoEtapa.capacidadPorUnidad} onValueChange={(e) => onInputChange(e, 'capacidadPorUnidad')} showButtons mode="decimal"></InputNumber>
                        </div>  

                        <div className="field">
                            <label htmlFor="capacidadPorTrabajo">Capacidad por trabajo(CPT)</label>
                            <InputNumber value={procesoEtapa.capacidadPorTrabajo} onValueChange={(e) => onInputChange(e, 'capacidadPorTrabajo')} showButtons mode="decimal"></InputNumber>
                        </div>  


                        <div className="field">
                            <label htmlFor="departamento">Departamentos: {departamento.departamento}</label>
                            <ListBox value={departamento} onChange={(e) => updateFieldListBox(e.value)} options={departamentos} optionLabel="departamento" filter paginator rows={10} required/>
                            {submitted && !departamento.departamento && <Message severity="error" text="Selecciona una proceso." />}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProcesoDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {procesoEtapa && <span>¿Está seguro de que desea eliminar <b>{procesoEtapa.etapa}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {procesoEtapa && <span>¿Está seguro de que desea eliminar el proceso seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProcesosEtapas, comparisonFn);