import 'react-app-polyfill/ie11';
import {Auth0Provider} from '@auth0/auth0-react'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

const onRedirectCallback = appState => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

ReactDOM.render(
    <HashRouter>
    <Auth0Provider 
    domain='dev-od21611q4ckzbnic.us.auth0.com' 
    clientId='yFWPQUv7YnXfGncs1TGUzTfTVc9Fqd9R' 
     redirectUri={window.location.origin} 
   > 
        <ScrollToTop>
            <App></App>
        </ScrollToTop>

     </Auth0Provider>
     
    </HashRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();