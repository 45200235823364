import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import {Calendar} from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { PersonasService } from '../service/administracion/PersonasService';
import { Message } from 'primereact/message';
import ContentLoader, {List} from "react-content-loader"
import { MultiSelect } from 'primereact/multiselect';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';


const Personas = () => {

    const [checkboxValue, setCheckboxValue] = useState([]);

    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValue(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setPersona({
                ...persona,
                ["habilitado"]:e.checked
            });

           
    };

    let emptyPersona= {
        id: '',
        nombreCompleto:'',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        rfc: '',
        celular: '',
        correoElectronico: '',
        fechaNacimiento: '',
        fechaCreacion: '',
        habilitado: true
    }


    const [personas, setPersonas] = useState(null);
    const [usuarioDialog, setPersonaDialog] = useState(false);
    const [deleteClientDialog, setDeleteClientDialog] = useState(false);
    const [deleteClientsDialog, setDeletePersonasDialog] = useState(false);
    const [persona, setPersona] = useState(emptyPersona);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //const [isLoading, setLoading] = useState(true);
    const [value7, setValue7] = useState(new Date());

    const personasService = new PersonasService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isLoading,
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
            if(user) 
            {
                rolesModulosPorUsuarioService.mz(user.email,2).then(data =>{
                    if(!data.samx)
                    {
                        window.location.href = '/'
                    }
                    else
                    {
                        personasService.getAll().then(data => {setPersonas(data);console.log(data)});
            
                    }
                });
                
            }

    }, [isLoading, isAuthenticated]);

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }}/> */}
                    <span>{option.rol}</span>
                </div>
            );
        }

        return 'Selecciona Roles';
    };

  const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.rol}</span>
            </div>
        );
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setPersona(emptyPersona);
        setValue7(null);
        setSubmitted(false);
        setPersonaDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPersonaDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteClientDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeletePersonasDialog(false);
    }

    const saveUsuario = () => {
        setSubmitted(true);

        if (persona.nombre.trim() && persona.correoElectronico.trim() && persona.fechaNacimiento.toLocaleString().trim()){
            let _personas = [...personas];
            let _persona = { ...persona };

            if (persona.id) {
                const index = findIndexById(persona.id);
                personasService.update(persona);
                _personas[index] = _persona;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'persona actualizado', life: 3000 });
            }
            else {

                personasService.create(persona).then(data => {_persona.id = data.id;});
                _personas.push(_persona);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'persona creado', life: 3000 });
            }

            setPersonas(_personas);
            setPersonaDialog(false);
            setPersona(emptyPersona);
        }
    }

    const updateFechaNacimiento = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        persona.fechaNacimiento = actualizaFecha;
        setPersona(persona);
        setValue7(data);
        //console.log(persona);

            } catch (e) {
            console.log('Error')
            }

    }

    const editPersona = (personas) => {
        var d =new  Date(personas.fechaNacimiento);
        setPersona({ ...personas });
        setValue7(d);
        setPersonaDialog(true);
    }

    const confirmDeleteProduct = (personas) => {
        setPersona(personas);
        setDeleteClientDialog(true);
    }

    // const deleteProduct = () => {
    //     let _usuarios = usuarios.filter(val => val.id !== usuario.id);
    //     setUsuarios(_usuarios);
    //     setDeleteClientDialog(false);
    //     setUsuario(emptyUsuario);
    //     toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuario Deleted', life: 3000 });
    // }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < personas.length; i++) {
            if (personas[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeletePersonasDialog(true);
    }

   

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _persona = { ...persona };
        _persona[`${name}`] = val;

        setPersona(_persona);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.nombre}
            </>
        );
    }

    const correoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.correoElectronico}
            </>
        );
    }

    const apellidoPaternoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.apellidoPaterno}
            </>
        );
    }

    const apellidoMaternoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.apellidoMaterno}
            </>
        );
    }

    const rfcBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.rfc}
            </>
        );
    }

    const celularBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.celular}
            </>
        );
    }

    const fechaNacimientoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">correo</span>
                {rowData.fechaNacimiento}
            </>
        );
    }


    const habilitadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Habilitado</span>
                <Checkbox  checked={rowData.habilitado} readOnly="true"/>
               
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" />
            </>
        )
    }

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {formatCurrency(rowData.price)}
            </>
        );
    }

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Category</span>
                {rowData.category}
            </>
        );
    }

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                <Rating value={rowData.rating} readonly cancel={false} />
            </>
        );
    }

    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            {/* <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} /> */}
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            {/* <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} /> */}
        </>
    );

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`client-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editPersona(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Administrador de personas</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && personas ?
                    <DataTable ref={dt} value={personas} 
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} personas"
                        globalFilter={globalFilter} emptyMessage="No hay personas registradas." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="nombre" header="Nombre(s)" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="apellidoPaterno" header="Apellido paterno" sortable body={apellidoPaternoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="apellidoMaterno" header="Apellido materno" sortable body={apellidoMaternoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="rfc" header="RFC" sortable body={rfcBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="celular" header="Celular" sortable body={celularBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaNacimiento" header="Fecha nacimiento" sortable body={fechaNacimientoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="correoElectronico" header="Correo" sortable body={correoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="habilitado" header="Habilitado" sortable body={habilitadoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                : <List/>}
                    <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Detalle persona" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Id del usuario: {persona.id}</label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>
                        <div className="field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={persona.nombre} onChange={(e) => onInputChange(e, 'nombre')} required autoFocus className={classNames({ 'p-invalid': submitted && !persona.nombre })} />
                            {submitted && !persona.nombre && <Message severity="error" text="Nombre es requerido." />}
                        </div>

                        <div className="field">
                            <label htmlFor="nombapellidoPaternore">Apellido paterno</label>
                            <InputText id="apellidoPaterno" value={persona.apellidoPaterno} onChange={(e) => onInputChange(e, 'apellidoPaterno')} required autoFocus className={classNames({ 'p-invalid': submitted && !persona.apellidoPaterno })} />
                            {/* {submitted && !persona.apellidoPaterno && <Message severity="error" text="Nombre es requerido." />} */}
                        </div>

                        
                        <div className="field">
                            <label htmlFor="apellidoMaterno">Apellido materno</label>
                            <InputText id="apellidoMaterno" value={persona.apellidoMaterno} onChange={(e) => onInputChange(e, 'apellidoMaterno')} required autoFocus className={classNames({ 'p-invalid': submitted && !persona.apellidoMaterno })} />
                            {/* {submitted && !persona.apellidoPaterno && <Message severity="error" text="Nombre es requerido." />} */}
                        </div>
                        
                        <div className="field">
                            <label htmlFor="rfc">RFC</label>
                            <InputText id="rfc" value={persona.rfc} onChange={(e) => onInputChange(e, 'rfc')} required autoFocus className={classNames({ 'p-invalid': submitted && !persona.rfc })} />
                            {/* {submitted && !persona.apellidoPaterno && <Message severity="error" text="Nombre es requerido." />} */}
                        </div>

                        <div className="field">
                            <label htmlFor="celular">Celular</label>
                            <InputText id="celular" value={persona.celular} onChange={(e) => onInputChange(e, 'celular')} required autoFocus className={classNames({ 'p-invalid': submitted && !persona.celular })} />
                            {/* {submitted && !persona.apellidoPaterno && <Message severity="error" text="Nombre es requerido." />} */}
                        </div>

                        <div className="field">
                            <label htmlFor="correoElectronico">Correo</label>
                            <InputText id="correoElectronico" value={persona.correoElectronico} onChange={(e) => onInputChange(e, 'correoElectronico')} required autoFocus className={classNames({ 'p-invalid': submitted && !persona.correoElectronico })} />
                            {/* {submitted && !persona.apellidoPaterno && <Message severity="error" text="Nombre es requerido." />} */}
                        </div>
                        
                        <div className="field">
                                <label htmlFor="calendar">Fecha de nacimiento</label>
                                <Calendar inputId="calendar" value={value7} onChange={(e) => updateFechaNacimiento(e.value)}
                                dateFormat="mm/dd/yy"
                                ></Calendar>
                                {submitted && !persona.fechaNacimiento && <Message severity="error" text="Fecha de nacimiento es requerido." />}
                        </div>
                        
                        <div className="field-checkbox">
                                <Checkbox inputId="habilitado" name="option" value="Habilitado" checked={persona.habilitado} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">Habilitado</label>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteClientDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {persona && <span>Are you sure you want to delete <b>{persona.nombre}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {persona && <span>Are you sure you want to delete the selected usuarios?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Personas, comparisonFn);