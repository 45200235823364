import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProcesosEtapasEmplSetService } from '../service/laboratorio/ProcesosEtapasEmplSetService';
import { AutoComplete } from 'primereact/autocomplete';
import { ListBox } from 'primereact/listbox';
import { Message } from 'primereact/message';
import { InputNumber } from 'primereact/inputnumber';
import ContentLoader, {List} from "react-content-loader";
import { CountryService } from '../service/CountryService';
import {Calendar} from 'primereact/calendar';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { RolesModulosPorUsuarioService } from '../service/administracion/RolesModulosPorUsuarioService';

const ProcesosEtapasEmplSet = () => {

    const history = useHistory();
    const paramsString = "q=URLUtils.searchParams&topic=api";
    const params = new URLSearchParams(window.location.search);


    const updateDropdown = (data) =>{
        
        departamento.ksDepartamentos = data;
        setDepartamento(departamento);
        console.log(data);
        setProcesoMachote(data);
        console.log(departamento);
    }

    const updateAutoCompleteClean = () =>{

        try {

       // procesoMachote.ksEmpleados.id=data.id;
        //procesoMachote.ksEmpleados.ksPersona.nombreCompleto='';

        //setProcesoMachote(procesoMachote);
        //console.log(data)
        //console.log(departamento)

        //setProceso(data)

        } catch (e) {
            console.log('Error')
        }
}

    const updateAutoComplete = (data) =>{

        try {
         //   setTimeout(() => {
                
                
        procesoMachote.ksProceso.id=data.id;
        if (data.trim().length === 0)
        {
            
            procesoMachote.ksProceso.proceso='';
            setAutoFilteredValue([...procesos]);
        }
        else
        {
            procesoMachote.ksProceso.proceso=data.proceso;
            procesoMachote.ksProceso.proceso=data.costo;
        }

        setProcesoMachote(procesoMachote);

           // }, 250);

        } catch (e) {
            console.log('Error')
        }
}

    const updateFieldListBox = (data) =>{

        try {

         procesoMachote.ksDepartamentos.id=data.id;
         procesoMachote.ksDepartamentos.departamento=data.departamento;

        setProcesoMachote(procesoMachote);

        setDepartamento(data)

        } catch (e) {
            console.log('Error')
        }

    }


    const [checkboxValue, setCheckboxValue] = useState([]);
    const [checkboxValueFechaA, setCheckboxValueFechaA] = useState([]);

    const onCheckboxChangeFechaA = (e) => {
        let selectedValue = [...checkboxValueFechaA];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

            setCheckboxValueFechaA(selectedValue);

            /* alert(usuario.habilitado);
 alert(usuario.habilitado); */
            setProcesoMachote({
                ...procesoMachote,
                ["enviarCorreoAniversario"]:e.checked
            });

           
    };




    let emptyProcesoMachote = {
        id: '',
        costo: 0,
        etapa: '',
        comision: 0,
        capacidadPorUnidad: 0,
        capacidadPorTrabajo: 0,
        calculadoPorcentaje: true,
        porcentaje:0,
        numeroOrden: 0,
        fechaCreacion: '',
        ksProceso: {
            id: '',
            costo:0,
            proceso: '',
            fechaCreacion: ''
        },
        ksDepartamentos:{
            id: '',
            departamento: ''
        }
    };

    let emptyProceso= {
        id: '',
        costo:0,
        proceso: ''
    }

    let emptyDepartamento= {
        id: '',
        departamento:''
    }

    const [departamentos, setDepartamentos] = useState(null);
    const [procesos, setProcesos] = useState(null);
    const [departamentosProcesos, setDepartamentosProcesos] = useState(null);
    const [procesoMachoteDialog, setProcesoMachoteDialog] = useState(false);
    const [deleteProcesoDialog, setDeleteProcesoDialog] = useState(false);
    const [deleteClientsDialog, setDeleteClientsDialog] = useState(false);
    const [departamento, setDepartamento] = useState(emptyDepartamento);
    const [proceso, setProceso] = useState(emptyProceso);
    const [procesoMachote, setProcesoMachote] = useState(emptyProcesoMachote);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [isLoading, setLoading] = useState(true);
    const [selectedAutoValue, setSelectedAutoValue] = useState(null);
    const [autoFilteredValue, setAutoFilteredValue] = useState([]);
    const [autoValue, setAutoValue] = useState(null);
    const [value7, setValue7] = useState(null);

    const procesosEtapasEmplSetService = new ProcesosEtapasEmplSetService();
    const rolesModulosPorUsuarioService = new RolesModulosPorUsuarioService();

    const {
        isAuthenticated,
        loginWithRedirect,
        user,
      } = useAuth0();

      useEffect(() => {
        if(user) 
        {
            rolesModulosPorUsuarioService.mz(user.email,7).then(data =>{
                if(!data.samx)
                {
                    window.location.href = '/'
                }
                else
                {
                    procesosEtapasEmplSetService.getAll().then(data => {setDepartamentosProcesos(data.depProcesoEtapas);});
                    setLoading(false);
                }
            });
            
        }

}, [isLoading, isAuthenticated]);

    const updateFechaAniversario = (data) =>{

        try {
            
        var dd = data.getDate().toLocaleString().padStart(2, 0);
        var mm = data.getMonth() + 1;
        var yyyy = data.getFullYear();
        var actualizaFecha = mm.toLocaleString().padStart(2, 0)+"/"+dd+"/"+yyyy;
        //console.log(actualizaFecha);
        procesoMachote.fehcaAniversario = actualizaFecha;
        setProcesoMachote(procesoMachote);
        setValue7(data);
        //console.log(proceso);

            } catch (e) {
            console.log('Error')
            }

    }

    const searchCountry = (event) => {
       // setTimeout(() => {
            if (!event.query.trim().length) {

                
                setAutoFilteredValue([...procesos]);
                
            }
            else {
                  setAutoFilteredValue(procesos.filter((e) => {
                      return e.proceso.toLowerCase().startsWith(event.query.toLowerCase());
                  }));
            }
       // }, 50);
    };


    const hideDialog = () => {

        setSubmitted(false);
        setProcesoMachoteDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProcesoDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteClientsDialog(false);
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }

    const saveUsuario = () => {
        setSubmitted(true);
        let _procesosMachote = [...departamentosProcesos];
        let _procesoMachote = { ...procesoMachote };

        if (procesoMachote.etapa.trim() && procesoMachote.ksDepartamentos.departamento.trim()){

            if (procesoMachote.id) {
                console.log(procesoMachote)
                const index = findIndexById(procesoMachote.id);
                procesosEtapasEmplSetService.update(procesoMachote);
                _procesosMachote[index] = procesoMachote;
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'proceso machote actualizado', life: 3000 });
            }
            else {
                procesosEtapasEmplSetService.create(procesoMachote).then(data => _procesoMachote.id = data.id);
                //procesosEtapasEmplSetService.getAll().then(data => setDepartamentos(data));
                //_usuario.id = createId();
                //_usuario.image = 'client-placeholder.svg';
                _procesosMachote.push(_procesoMachote);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'proceso machote creado', life: 3000 });
            }

            setDepartamentosProcesos(_procesosMachote);
            setProcesoMachoteDialog(false);
            setDepartamento(emptyDepartamento);
            setProcesoMachote(emptyProcesoMachote);
            //setProcesos(procesos.filter((p) => p.id !== proceso.id ));
        }
    }


    const deleteProduct = () => {
        let _procesosMachote = departamentosProcesos.filter(val => val.id !== procesoMachote.id);
        
        procesosEtapasEmplSetService.delete(procesoMachote.id);
        setDepartamentosProcesos(_procesosMachote);
        setDeleteProcesoDialog(false);
        setProcesoMachote(emptyProcesoMachote);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Proceso eliminado', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < departamentosProcesos.length; i++) {
            if (departamentosProcesos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const findIndexByIdProcesosEtapas = (id) => {
        let index = "-1";
        for (let i = 0; i < departamentosProcesos.length; i++) 
        {
            for (let f = 0; f < departamentosProcesos[i].procesoEtapas.length; f++) 
            {
                
                if (departamentosProcesos[i].procesoEtapas[f].id === id) {
                    index = i+"-"+f;
                    break;
                }
            }

            if(index != "-1")
            {
                break;
            }
        }

        return index;
    }

    const findProcesoById = (id) => {
        let index = -1;
        for (let i = 0; i < procesos.length; i++) {
            if (procesos[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const deleteSelectedProducts = () => {
        let _usuarios = departamentos.filter(val => !selectedProducts.includes(val));
        setDepartamentos(_usuarios);
        setDeleteClientsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'usuarios Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _procesoMachote = { ...procesoMachote };
        _procesoMachote[`${name}`] = val;

        setProcesoMachote(_procesoMachote);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/* <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} /> */}
                    
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">id</span>
                {rowData.id}
            </>
        );
    }


    const ventaIdOrdenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ventaId</span>
                {rowData.ksVentas.id}
            </>
        );
    }

    const ksVentaPrincipalOrdenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ventaId</span>
                {rowData.ksVentas.ksVentaPrincipal.id}
            </>
        );
    }

    const etapaSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">etapa</span>
                {rowData.etapa}
            </>
        );
    }

    const pacienteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">paciente</span>
                {rowData.ksVentas.ksVentaPrincipal.paciente}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">status</span>
                {rowData.status}
            </>
        );
    }

    const usuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">usuario</span>
                {rowData.usuario}
            </>
        );
    }

    const nombreComercialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">nombreComercial</span>
                {rowData.ksVentas.ksVentaPrincipal.ksCliente.nombreComercial}
            </>
        );
    }

    const costoTotalProcesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">costoTotalProceso</span>
                {currencyFormat(rowData.ksProceso.costo)}
            </>
        );
    }

    const departamentoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">departamento</span>
                {rowData.departamento}
            </>
        );
    }


    const comisionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">comision</span>
                {currencyFormat(rowData.comision)}
            </>
        );
    }

    const porcentajeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">porcentaje</span>
                %{rowData.porcentaje}
            </>
        );
    }

    const procesoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">proceso</span>
                {rowData.proceso}
            </>
        );
    }


    const calculadoPorcentajeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">calculadoPorcentaje</span>
                <Checkbox  checked={rowData.calculadoPorcentaje} readOnly="true"/>
               
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProcesoMachote(rowData)} /> */}
                {rowData.usuario && rowData.status === 'ENPROCESO' ?

                    rowData.usuario  === user.email ?
                        <Button icon="pi pi-power-off" className="p-button-rounded p-button-success mr-2 mb-2" 
                        onClick={() => confirmProcesosEmpleadoFinished(rowData)} 
                        disabled={false}/>
                        :
                        <Button icon="pi pi-check" className="p-button-rounded p-button-warning mr-2 mb-2"/>

                    : 
                    rowData.usuario && rowData.status === 'FINALIZADO' ?
                        <Button icon="pi pi-check" className="p-button-rounded p-button-success mr-2 mb-2"/>
                        :
                        <Button icon="pi pi-power-off" className="p-button-rounded p-button-warning mr-2 mb-2" onClick={() => confirmProcesosEmpleadoAsig(rowData)}/>
                        
                }
                
               
            </div>
        );
    }

    const confirmProcesosEmpleadoFinished = (procesoEtapa) => {
       
         setLoading(true);

         procesosEtapasEmplSetService.finalizar(procesoEtapa.id, user.email).then(data => {
            
        
             if(data)
             {

                procesoEtapa.status = "FINALIZADO";
        
                const index = findIndexByIdProcesosEtapas(procesoEtapa.id);
                //findIndexByIdProcesosEtapas(index)
                console.log(procesoEtapa);
                const answer_array = index.split('-');

                let _departamentosProceso = [...departamentosProcesos];
                //console.log(_departamentosProceso[parseInt(answer_array[0])].procesoEtapas[parseInt(answer_array[1])] );
                _departamentosProceso[parseInt(answer_array[0])].procesoEtapas[parseInt(answer_array[1])] = procesoEtapa;
                setDepartamentosProcesos(_departamentosProceso);

                 toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Etapa finalizada por '+user.email, life: 7000 });
             }
             else
             {
                 toast.current.show({ severity: 'warning', summary: 'Adventencia', detail: 'Falla finalización del usuario '+user.email+' en la etapa '+procesoEtapa.etapa+'.', life: 7000 });
             }

             setLoading(false);

         });


    }

    const confirmProcesosEmpleadoAsig = (procesoEtapa) => {
       
        setLoading(true);

        procesosEtapasEmplSetService.update(procesoEtapa.etapa, procesoEtapa.id, user.email).then(data => {
            
        
            if(data)
            {

                procesoEtapa.usuario = user.email;
                procesoEtapa.status = 'ENPROCESO';
        
                const index = findIndexByIdProcesosEtapas(procesoEtapa.id);
                //findIndexByIdProcesosEtapas(index)
                console.log(procesoEtapa);
                const answer_array = index.split('-');

                let _departamentosProceso = [...departamentosProcesos];
                //console.log(_departamentosProceso[parseInt(answer_array[0])].procesoEtapas[parseInt(answer_array[1])] );
                _departamentosProceso[parseInt(answer_array[0])].procesoEtapas[parseInt(answer_array[1])] = procesoEtapa;
                setDepartamentosProcesos(_departamentosProceso);

                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Etapa asignado a '+user.email, life: 7000 });
            }
            else
            {
                toast.current.show({ severity: 'warning', summary: 'Adventencia', detail: 'Usuario '+user.email+' tiene carga de trabajo a su máxima capacidad en la etapa '+procesoEtapa.etapa+'.', life: 7000 });
            }

            setLoading(false);

        });


    }

    
    const [expandedRows, setExpandedRows] = useState(null);

    const expandAll = () => {
        let _expandedRows = {};
        departamentosProcesos.forEach(p => _expandedRows[`${p.id}`] = true);

        setExpandedRows(_expandedRows);
    }

    const collapseAll = () => {
        setExpandedRows(null);
    }

    const header = (
        

        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Ordenes en los departamentos del laboratorio</h5>
            {/* <Button label="Procesos" icon="pi pi-arrow-left" className="p-button-rounded mr-2" onClick={()=> history.push("/procesos")}/>  */}
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="mr-2 mb-2" />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} className="mb-2" />
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    const descargarArchivoBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.archivo ?
                    <Button icon="pi pi-download" className="p-button-rounded p-button-success mt-2"
                    onClick={() => openInNewTab("https://kreativlabserv.com.mx/api-utils-1/UploadFIEL?idVentaPrincipal="+rowData.ksVentas.ksVentaPrincipal.id)}/>
                : 
                    ""
                }
                
            </div>
        );
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Departamento {data.departamento}</h5>
                <DataTable value={data.procesoEtapas} responsiveLayout="scroll">
                    <Column field="ksVentas.ksVentaPrincipal.id" header="No. Trabajo" sortable body={ksVentaPrincipalOrdenBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                    <Column hidden={true} field="ksVentas.id" header="Proceso Id" sortable body={ventaIdOrdenBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                    <Column hidden={true} field="id" header="Etapa id" sortable body={codeBodyTemplate} headerStyle={{ width: '5%', minWidth: '5rem' }}></Column>
                    <Column field="etapa" header="Etapa" sortable body={etapaSocialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="proceso" header="Proceso" sortable body={procesoBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="ksVentas.ksVentaPrincipal.ksCliente.nombreComercial" header="Cliente" sortable body={nombreComercialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="ksVentas.ksVentaPrincipal.paciente" header="Paciente" sortable body={pacienteBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="status" header="Status proceso" sortable body={statusBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="usuario" header="Usuario asignado" sortable body={usuarioBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    
                    <Column body={descargarArchivoBodyTemplate} header="Descargar archivo"></Column>
                    <Column body={actionBodyTemplate}></Column> 
                    {/* <Column field="customer" header="Customer" sortable></Column>
                    <Column field="date" header="Date" sortable></Column>
                    <Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column>
                    <Column field="status" header="Status" body={statusOrderBodyTemplate} sortable></Column>
                    <Column headerStyle={{ width: '4rem' }} body={searchBodyTemplate}></Column> */}
                </DataTable>
            </div>
        );
    }

    return (
        
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    
                    {/* <DataTable ref={dt} value={usuarios} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} */}
                {!isLoading && departamentosProcesos ?
                    <DataTable ref={dt} 
                    value={departamentosProcesos} 
                    expandedRows={expandedRows} 
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate} 
                    dataKey="id" 
                    paginator rows={10} 
                    rowsPerPageOptions={[5, 10, 25]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} subprocesos"
                        globalFilter={globalFilter} emptyMessage="No hay subprocesos." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        {/* <Column field="id" header="Id" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="comision" header="Comisión" sortable body={comisionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="porcentaje" header="%" sortable body={porcentajeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="numeroOrden" header="Número de orden del proceso" sortable body={numeroOrdenBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                         */}
                        <Column expander style={{ width: '3em' }} />
                        <Column field="departamento" header="Departamento" sortable body={departamentoBodyTemplate} 
                        // headerStyle={{ width: '14%', minWidth: '10rem' }}
                        ></Column>
                    </DataTable>
                : <List/>}
                    <Dialog visible={procesoMachoteDialog} style={{ width: '450px' }} header="Detalle procesoMachote" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                       {/*    {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       */}
                        <div className="field">
                            <label htmlFor="id">Id del proceso machote: {procesoMachote.id}</label>
                           {/*  <InputText id="id" readOnly="true" value={usuario.id} onChange={(e) => onInputChange(e, 'id')} required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.name })} />
                            {submitted && !usuario.id && <small className="p-invalid">Id is required.</small>} */}
                        </div>      
                        <div className="field">
                            <label htmlFor="procesos">Proceso: {procesoMachote.ksProceso.proceso} </label>
                            {/* <AutoComplete placeholder="Buscar" id="dd" dropdown value={procesoMachote.ksProceso.proceso} 
                            onChange={(e) => updateAutoComplete(e.value)}  
                            suggestions={autoFilteredValue} 
                            blurOnSelect={true}
                            completeMethod={searchCountry} 
                            field="proceso" 
                            readOnly true
                            />
                            {submitted && !procesoMachote.ksEmpleados.ksPersona.nombreCompleto && <Message severity="error" text="Selecciona un departamento." />} */}
                        </div> 
                        <div className="field">
                            <label htmlFor="id">Costo total del proceso: {currencyFormat(procesoMachote.ksProceso.costo)}</label>
                        </div>   
                       
                        <div className="field">
                            <label htmlFor="etapa">Etapa</label>
                            <InputText id="etapa" value={procesoMachote.etapa} onChange={(e) => onInputChange(e, 'etapa')} required autoFocus className={classNames({ 'p-invalid': submitted && !procesoMachote.etapa })} />
                            {submitted && !procesoMachote.etapa && <Message severity="error" text="Etapa es requerido." />}
                        </div>  

                        <div className="field">
                            <label htmlFor="comision">Comisión $</label>
                            <InputNumber value={procesoMachote.comision} onValueChange={(e) => onInputChange(e, 'comision')} showButtons mode="decimal"
                            disabled = {procesoMachote.calculadoPorcentaje}
                            ></InputNumber>
                        </div> 
                        

                        

                        <div className="field">
                            <label htmlFor="numeroOrden"># Orden</label>
                            <InputNumber value={procesoMachote.numeroOrden} onValueChange={(e) => onInputChange(e, 'numeroOrden')} showButtons mode="decimal"></InputNumber>
                        </div>  
                        
                        <div className="field">
                            <label htmlFor="capacidadPorUnidad">Capacidad por unidad(CPU)</label>
                            <InputNumber value={procesoMachote.capacidadPorUnidad} onValueChange={(e) => onInputChange(e, 'capacidadPorUnidad')} showButtons mode="decimal"></InputNumber>
                        </div>  

                        <div className="field">
                            <label htmlFor="capacidadPorTrabajo">Capacidad por trabajo(CPT)</label>
                            <InputNumber value={procesoMachote.capacidadPorTrabajo} onValueChange={(e) => onInputChange(e, 'capacidadPorTrabajo')} showButtons mode="decimal"></InputNumber>
                        </div>  


                        <div className="field">
                            <label htmlFor="departamento">Departamentos: {departamento.departamento}</label>
                            <ListBox value={departamento} onChange={(e) => updateFieldListBox(e.value)} options={departamentos} optionLabel="departamento" filter paginator rows={10} required/>
                            {submitted && !departamento.departamento && <Message severity="error" text="Selecciona una proceso." />}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProcesoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {procesoMachote && <span>¿Está seguro de que desea eliminar <b>{procesoMachote.etapa}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteClientsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {procesoMachote && <span>¿Está seguro de que desea eliminar el proceso seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ProcesosEtapasEmplSet, comparisonFn);